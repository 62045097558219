import React from "react";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import { BsPen } from "react-icons/bs"
import Aos from "aos";
import axios from "../../../axios";
import { toast } from "react-toastify";
import Map from "./EditMap/editMap";
import AddLocationModal from "../../../components/Modal/salonModals/addLocation"
import "./editSalon.scss"
import iconLocation from "../../../assets/images/8666640-64.png"
import WorkingHoursModal from "../../../components/Modal/WorkingHoursModal/workingHours"
import { useNavigate } from "react-router";
import { Salon, LabelProfile, InputProfileForm, DivForLabelAndInput, EditButtonSalon, DeleteButtonSalon } from "../../../components/styles/MainLayout"
import { setSideBar } from "../../../redux/Functions/actions";
import { connect } from "react-redux";
import Lightbox from 'react-image-lightbox';
import SearchInputHeader from "../../../components/form/SearchInputHeader";
import AddLocationFormSalon from "../../../components/form/salons/addLocationFormSalon";
import SalonServiceDeleteModal from "./SalonServiceDeleteModal";

const EditSalonView = (props) => {
  const [editTrigger, setEditTrigger] = useState(null);
  const [showName, setShowName] = useState(false);
  const [showdesc, setShowdesc] = useState(false);
  const [showServices, setShowServices] = useState(true)
  const navigate = useNavigate();


  // const [locationVal, setLocationVal] = useState()
  const [locationValEn, setLocationValEn] = useState()
  const [showMap, setShowMap] = useState(false)
  const [salonLocations, setSalonLocations] = useState([])
  const [imageUrl, setImageUrl] = useState()
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeInput, setActiveInput] = useState(-1);
  const [editName, setEditName] = useState("")
  // const [images , setImages] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isOpenLightbox, setIsOpenLightbox] = useState(false);
  const [addressId, setAddressId] = useState()
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgDelete, setImgDelete] = useState(false)
  const [handleImgIndex, setHandleImgIndex] = useState()
  const [services, setServices] = useState()
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [isOpenWorkingHours, setIsOpenWorkingHours] = useState([]);
  const [modalType, setModalType] = useState("")
  const [dataToModal, setDataToModal] = useState(null);
  const [activeServiceIndex, setActiveServiceIndex] = useState(null);
  const [isFav, setIsFav] = useState(false)
  const [salonServiceId, setSalonServiceId] = useState([])
  const [showSubServices, setSubShowServices] = useState(false)
  const [imageTitle, setImageTitle] = useState()
  const [editMode, setEditMode] = useState(false);
  const [subServices, setSubServices] = useState([])
  const [editingIndex, setEditingIndex] = useState(null);


  // const [lastID , setLastId] = useState()
  const { id } = useParams();
  useEffect(() => {

    console.log('pramsid', id)
  }, [])

  Aos.init();
  //API 
  const DeleteSalon = (id) => {
    axios.delete(`/api/admin/salon_address/${id}`).then(
      (res) => {
        toast.success("Address Deleted");
      }
    ).catch(
      (err) => {
        console.log(err)
        toast.success("Something went wrong, try again later");
      }
    )
  }
  const getSalonAddressService = () => {
    axios.get(`/api/admin/salon_services/all`).then(
      response => {
        console.log("salon address single", response)
        setSalonServiceId(response.data)
      }
    ).catch(err => {
      console.log(err)
    })
  }

  const EditSalon = (e) => {
    e.preventDefault();
    let data = {
      id: id,
      name_al: e.target.name_al.value,
      name_en: e.target.name_al.value,
      desc_al: e.target.desc_al.value,
      desc_en: e.target.desc_al.value,
      created_by: props.user_data.id
    };
    axios.put(`/api/admin/salon_address/update`, data)
      .then(response => {
        console.log("responsegashi nanush", response)
        toast.success("Salon updated successfully");
        setShowdesc(false);
        setShowServices(true);

        const formData = new FormData();
        formData.append('salon_id', id);
        formData.append('img_url', imageUrl);
        formData.append('img_alt', imageTitle);
        axios.post(`/api/admin/salon_images`, formData).then(
          data => {
            console.log("image shkoj", data.data)
            getSingleSalonAddress()
            setImageUrl(null)
            setSelectedImage(null)
            setImageTitle("")
          }
        ).catch(err => {
          console.log(err)
          toast.error("image Didnt upload")
        })
      })
      .catch(err => {
        console.log(err);
        toast.error("Something went wrong, try again later")
      });
  };

  const getSingleSalonAddress = () => {
    axios.get(`/api/admin/salon_address/single/${id}`)
      .then(data => {
        console.log("SALON ADRES", data.data)
        setSalonLocations(data.data)
      }).catch(err => {
        console.log(err)
      })
  }
  const DeleteImage = (imgID) => {
    axios.delete(`/api/admin/salon_images/${imgID}`).then(
      toast.success("Image Deleted"),
    ).catch(err => {
      console.log(err)
      toast.error("image didnt get deleted")
    })
  }


  //Delete service of salon



  const getServices = () => {
    axios.get('/api/admin/services/all').then(
      data => {
        console.log("serviset", data.data)
        setServices(data.data)
      }
    ).catch(
      err => {
        console.log(err)
      }
    )
  }
  const [value, setValue] = useState("")
  const duration = Math.floor(value / 60);
  const remainingDuration = value % 60;
  const formatDuration = String(duration).padStart(2, '0');
  const formattedMinutes = String(remainingDuration).padStart(2, '0');
  let time = `${formatDuration}:${formattedMinutes}:00`
  console.log('props 123', props)
  const addSalonService = (subID, sub_subID, name_al, e, is_fav) => {
    e.preventDefault()
    let body = {
      salon_address_id: id,
      service_id: selectedTabs[0],
      sub_service_id: subID,
      sub_sub_service_id: sub_subID,
      salon_id: salonLocations.salon_id,
      name_al: name_al,
      name_en: name_al,
      is_fav: isFav != null ? `${isFav}` : `${is_fav}`,
      duration: time == "00:00:00" ? null : time,
    }
    // console.log("ADD SERVICE", body)
    axios.post("/api/admin/salon_services", body).then(data => {
      console.log(data.data)
      toast.success("Services have been added successfully")
      getSingleSalonAddress();
      getServices()
      getSalonAddressService()
    }
    ).catch(err => {
      console.log(err)
    })
  }
  const handleModalStaf = (data) => {
    setIsOpen(true);
    setModalType("staf");
    setDataToModal(data)
  };
  const handleAddModalStaf = (subid, responseid, responsename_al, value, serviceId, salon_id, isFav) => {
    if (value == "") {
      toast.warning("please select a time value")
    }
    else {
      setIsOpen(true);
      setModalType("add");
      setDataToModal([subid, responseid, responsename_al, value, serviceId, salon_id, isFav])
    }
  };
  const handleAll = () => {
    const allIds = services.map((category) => category.id);
    setSelectedTabs(allIds);

  }


  const handleLightboxOpen = () => {
    setIsOpenLightbox(true);
  };

  const handleLightboxClose = () => {
    setIsOpenLightbox(false);
  };
  console.log("selectedTabs", selectedTabs)
  const staticName = salonLocations?.name_al
  console.log("urim", salonLocations.salon_sub_services)

  //API
  //Functions 
  function handleMap(e) {
    e.preventDefault()
    setShowMap(true)
  }
  const handleEdit = (index) => {
    setActiveIndex(index);
  };
  const handleImage = (index) => {
    setHandleImgIndex(index);
    setImgDelete(true)
  };
  const handleActive = (index, id) => {
    setActiveInput(index);
    setAddressId(id)
  };

  const handleModalAdd = () => {
    setIsOpen(true);
  };


  const handleDeleteSalonService = (sid) => {
    axios.delete(`/api/admin/salon_services/${sid}`)
      .then((res) => {
        toast.success("u fshi")
        getSingleSalonAddress()
        getSalonAddressService()
        getServices()
      })
      .catch(err => toast.error(err))

  }

  const handleModalDelete = (data) => {
    setIsOpenDelete(true);
    setModalType("delete");
    setDataToModal(data)
  }

  const handleModalClose = () => {
    setIsOpen(false);
    setIsOpenDelete(false)
    setIsOpenWorkingHours(false);
  };
  const handleModal = (addressID) => {
    setIsOpenWorkingHours(true);
    setModalType("add")
    setAddressId(addressID)
  }
  const handleTabClick = (id) => {

    axios.get(`/api/admin/sub_services/single/${id}`)
      .then(data => {
        setSubServices(data.data)
      })
      .catch(err => {
        console.log(err)
      }
      )

    if (selectedTabs.includes(id)) {
      setSelectedTabs(selectedTabs.filter((tabId) => tabId !== id));
      setActiveServiceIndex(null);
    } else {
      setSelectedTabs([id]);
      setActiveServiceIndex(id);
      setEditTrigger(null)

      // getSubServices()
    }
    setSubShowServices(true);
  };
  const handleImageUpload = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage(reader.result);
    };

    if (e) {
      reader.readAsDataURL(e);
    }
  };
  const serviceID = salonServiceId.filter(elem => elem.salon_address_id == id).map(el => el?.sub_sub_service_id)

  const handleEditImage = (index) => {
    console.log("setEditTrigger", index)
    setEditTrigger(index)

  }
  const [changeImageDesc, setChangeImageDesc] = useState()
  const updateImageData = (el) => {
    // formData.append('img_url', imageUrl);
    let imgBody = {
      id: el.id,
      salon_id: id,
      img_alt: changeImageDesc,
    };
    console.log("formData", imgBody)
    axios.put(`/api/admin/salon_images`, imgBody).then(
      data => {
        console.log("image shkoj", data.data)
        toast.success("image edited")
        getSingleSalonAddress()
        setImageUrl(null)
        setSelectedImage(null)
        setImageTitle("")
      }
    ).catch(err => {
      console.log(err)
      toast.error("image Didnt upload")
    })

    console.log("updateImageDate", el)
    // setEditTrigger(index)

  }
  const EditSalonService = (subID, sub_subID, name_al, e, ids, salonId, is_fav) => {
    e.preventDefault()
    let body = {
      id: ids,
      salon_address_id: id,
      service_id: selectedTabs[0],
      sub_service_id: subID,
      sub_sub_service_id: sub_subID,
      salon_id: salonId,
      name_al: name_al,
      name_en: name_al,
      is_fav: isFav != null ? `${isFav}` : `${is_fav}`,
      duration: time == "00:00:00" ? null : time,
    }
    console.log("EDIT SERVICE", body)
    axios.put("/api/admin/salon_services/update", body).then(
      toast.success("Services have been added successfully"),
      setEditingIndex(null)
    ).catch(err => {
      console.log(err)
    })
  }
  // const navigateToContacts = () => {
  //   navigate('/workingHours/:');
  // };
  // console.log("hajde",salonLocations?.salon_sub_services)
  const DeleteService = (id) => {
    axios.delete(`/api/admin/salon_services/${id}`).then(
      toast.success("Service Deleted"),
      getServices(),
      getSalonAddressService(),
    ).catch(err => {
      console.log(err)
      toast.error("Service didnt deleted")
    })
  }
  const handleEditWorkingHours = (e, el) => {
    e.preventDefault()
    console.log('CHECK', e.target.work_this_day.checked)
    let body = {
      id: el.id,
      start_time: e.target.start_time.value != el?.start_time.slice(11, 19) ? `2023-05-27T${e.target.start_time.value}:00.000Z` : null,
      end_time: e.target.end_time.value != el?.end_time.slice(11, 19) ? `2023-05-27T${e.target.end_time.value}:00.000Z` : null,
      brake_start: e.target.brake_start.value != el?.brake_start.slice(11, 19) ? `2023-05-27T${e.target.brake_start.value}:00.000Z` : null,
      brake_end: e.target.brake_startbrake_end.value != el?.brake_end.slice(11, 19) ? `2023-05-27T${e.target.brake_startbrake_end.value}:00.000Z` : null,
      work_this_day: `${e.target.work_this_day.checked}`
    }
    console.log("edit wh", body)
    axios.put("/api/admin/salon_working_hours/update_agenda", body).then(data => {
      console.log(data.data)
      toast.success("Working Hours Updated!");
      setEditTrigger(null)
    }
    ).catch(err => {
      console.log(err)
      toast.error("something went wrong")
    })
  }
  useEffect(() => {
    getSingleSalonAddress();
    getServices()
    getSalonAddressService()

  }, [])
  return (
    <>
      <SearchInputHeader />
      {isOpen == true ? (
        <AddLocationFormSalon
          getSalonAddressService={getSalonAddressService}
          getSingleSalonAddress={getSingleSalonAddress}
          isOpen={isOpen}
          handleModalClose={handleModalClose}
          data={dataToModal}
          type={modalType}
        />
      ) : (
        ""
      )}

      {isOpenWorkingHours === true ? (
        <WorkingHoursModal
          isOpen={isOpenWorkingHours}
          handleModalClose={handleModalClose}
          type={modalType}
          id={addressId}
        />
      ) : (
        ""
      )}
      {isOpenDelete === true ? (
        <SalonServiceDeleteModal
          getSingleSalonAddress={() => getSingleSalonAddress()}
          getSalonAddressService={() => getSalonAddressService()}
          getServices={() => getServices()}
          isOpen={isOpenDelete}
          type={modalType}
          dataToModal={dataToModal}
          handleModalClose={handleModalClose}
        />
      ) : (

        ""
      )}
      <div className="container bgSalon">
        <Salon collapse={props?.sidebar}>
          {showName == true ?

          <></>
            // <> City: <input className="salonName w-auto" defaultValue={staticName}></input> <BsPen role="button" style={{ fontSize: '20px', marginBottom: '20px' }} onClick={() => setShowName(!showName)} /></>
            :
            
            // <p className="salonName w-auto" >City:  {staticName}
            //   <BsPen role="button" style={{ fontSize: '20px', marginBottom: '20px' }} onClick={() => setShowName(!showName)} />
            //   </p>
            <></>
          }
          <form onSubmit={EditSalon}>
            <div class="container"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="150"
            >
              <div class="mb-5"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="150">
                <div class="col-md-3 col-12 align-self-start">
                  <input required className="form-control" type="text" name="name_al" id="name_al" defaultValue={salonLocations?.name_al} placeholder="Emri Sallonit" />
                </div>
                {/* <div class="col-3 mt-3 align-self-center">
                  <input className="form-control" type="text" name="name_en" id="name_en" defaultValue={salonLocations?.name_en} placeholder="Salon Name" />
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="mt-2">Salon Images</p>
              </div>

              <div className="iconUploadImg">
                <div class="container">
                  <div class="row"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="150"
                  >
                    <div className="col-12">
                      {salonLocations?.salon_imgs?.map((el, index) => {
                        return (
                          <>
                            <div className="row shaban">
                              <div className="col-4 divImageContainer">
                                <img src={`${process.env.REACT_APP_API}${el.img_url}`} alt={el.img_alt} className="imageEditSalon" onClick={handleLightboxOpen} />
                              </div>

                              {/* Show the Lightbox when isOpenLightbox is true */}
                              {isOpenLightbox && (
                                <Lightbox
                                  mainSrc={`${process.env.REACT_APP_API}${el.img_url}`}
                                  onCloseRequest={handleLightboxClose}
                                />
                              )}
                              <div className="col-4">
                                <div className="d-flex">
                                  <DivForLabelAndInput className="forDisable" disabled={editTrigger == index ? true : ""}>
                                    <LabelProfile > Image Description </LabelProfile>
                                    <InputProfileForm defaultValue={el.img_alt} type="text" onChange={(e) => setChangeImageDesc(e.target.value)} trigger={editTrigger} disabled={editTrigger != index ? true : ""} />
                                  </DivForLabelAndInput>
                                </div>
                              </div>
                              <div className="col-4 butonatEditDelete">
                                <div className="d-flex gap-2 forButtons">
                                  {editTrigger !== index ?
                                    <EditButtonSalon onClick={(e) => (e.preventDefault(), handleEditImage(index))}>Edit</EditButtonSalon>
                                    : <EditButtonSalon onClick={(e) => (e.preventDefault(), updateImageData(el))}>Save</EditButtonSalon>
                                  }
                                  {editTrigger !== index ?
                                    <DeleteButtonSalon onClick={(e) => { e.preventDefault(); DeleteImage(el.id) }}>Delete</DeleteButtonSalon>
                                    : <DeleteButtonSalon onClick={(e) => { e.preventDefault(); setEditTrigger(null) }}>Cancel</DeleteButtonSalon>
                                  }
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                    {/* {salonLocations?.salon_imgs?.map((el, index) => {
                      console.log("el piqku mate", el)
                      return (
                        <>
                          <div class="col-sm-4"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="150"
                          >
                            {handleImgIndex != salonLocations?.id ?
                              <img src={`${process.env.REACT_APP_API}${el.img_url}`} alt="asd" className="salonImages"
                                onClick={() => handleImage(index)} />
                              :
                              <button className="btn btn-md btn-danger salonImages" onClick={() => DeleteImage(el.id)}><strong>Confirm Delete </strong></button>
                            }
                          </div>
                        </>
                      )
                    })} */}
                  </div>
                </div>
              </div>

              <div className="border pt-5 pb-5 chooseFile"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="150">
                <img src={selectedImage} alt="" style={{ maxWidth: '600px', maxHeight: '200px' }} />
                <label className="custom-file-upload">
                  <input type="file" name="img_url" id="img_url" onChange={(e) => { setImageUrl(e.target.files[0]); handleImageUpload(e.target.files[0]) }} />
                </label>
                <input type="text" placeholder="image title" className="form-control mt-1" onChange={(e) => { setImageTitle(e.target.value) }} />
              </div>
            </div>
            <div className="row justify-content-between">
<div className="col-12 col-md-6 p-0">

            <p className="salonName" role="button" onClick={() => setShowdesc(true)}>Description <BsPen style={{ fontSize: '20px', marginBottom: '20px' }} /></p>
            <div class="container"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="150"
            >
              <div class=" row mb-5">
                <div class="col align-self-start">
                  <textarea required className="form-control" name="desc_al" id="desc_al" defaultValue={salonLocations?.desc_al} placeholder="Pershkrimi Sallonit" cols="50" rows="7"></textarea>
                </div>
                {/* <div class="col align-self-center">
                  <textarea className="form-control" name="desc_en" id="desc_en" defaultValue={salonLocations?.desc_en} placeholder="Salon Description" cols="50" rows="7"></textarea>
                </div> */}
              </div>
            </div>
            </div>
<div className="col-12 col-md-6 p-0">

            <p className="salonName" role="button" onClick={() => setShowdesc(true)}>Description <BsPen style={{ fontSize: '20px', marginBottom: '20px' }} /></p>
            <div class="container"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="150"
            >
              <div class=" row mb-5">
                <div class="col align-self-start">
                  <textarea required className="form-control" name="desc_al" id="desc_al" defaultValue={salonLocations?.desc_al} placeholder="Pershkrimi Sallonit" cols="50" rows="7"></textarea>
                </div>
                {/* <div class="col align-self-center">
                  <textarea className="form-control" name="desc_en" id="desc_en" defaultValue={salonLocations?.desc_en} placeholder="Salon Description" cols="50" rows="7"></textarea>
                </div> */}
              </div>
            </div>
            </div>
            </div>

            <div className="slideShowSalon mt-5">
              <button className="btn btn-md btn-success  mb-2" type="submit">Edit</button>

            </div>
          </form>

          {/* SHEBRIMET */}

          {/* THINGS to CHANGE:
        -remove d-flex from divs
        - style all the divs, and elements
        -reponsivity
      -add other elements */}
          {showServices === true ?
            <div className="row">
              <div className="line"></div>
              <div className="container services">
                <Tabs>
                  <div className="container">
                    <h6 className="my-4 browseServices">Choose Services:</h6>
                    <div className="container checkServices">
                      <TabList className="row"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="150"
                      >
                      </TabList>
                      {services?.map(el => {
                        return (
                          <>
                            <div
                              className={`d-flex justify-content-center servicediv mb-3 ${selectedTabs.includes(el.id) ? 'actives' : ''}`}
                              onClick={() => handleTabClick(el.id)}
                            >
                              <p className="pt-2"><strong>{el.name_al}</strong></p>
                            </div>
                            {selectedTabs.includes(el.id) && activeServiceIndex === el.id && (
                              <>
                                {subServices?.map(sub => {
                                  // console.log('subbbbbb', sub?.salon_address_id)
                                  return (
                                    <>
                                      <div
                                        className="subService servicediv d-flex justify-content-center mb-2"
                                        data-aos="fade-down"
                                        data-aos-easing="linear"
                                        data-aos-duration="150"

                                      >
                                        <ul class="list-group w-100 mb-5">
                                          <strong className="text-danger">{sub?.name_al}</strong>
                                          {sub?.sub_sub_services?.map(response => {
                                            return (
                                              <li class="list-group-item">
                                                <div class="row">
                                                  <div class="col-sm">
                                                    <strong>{response.name_al}</strong>
                                                  </div>
                                                  {console.log("arton", serviceID)}
                                                  <div class="col-3">
                                                    time :

                                                    {serviceID.includes(response.id) ?
                                                      salonLocations?.salon_sub_services?.filter(el => el.sub_sub_service_id == response.id).map((el,index) => {
                                                        const time = el?.duration;
                                                        const timeParts = time.split(':');
                                                        const hours = parseInt(timeParts[0]);
                                                        const minutes = parseInt(timeParts[1]);
                                                        const totalMinutes = hours * 60 + minutes;
                                                        return (
                                                          <>
                                                            <input  disabled={editingIndex != el.id} defaultValue={totalMinutes} className="inputDuration" type="number" onChange={(e) => setValue(e.target.value)} /> <span>min</span>
                                                          </>
                                                        )
                                                      })
                                                      :
                                                      <>

                                                        <input type="number" className="inputDuration" onChange={(e) => setValue(e.target.value)} />
                                                        min
                                                      </>

                                                    }
                                                  </div>
                                                  <div class="col-sm">
                                                    is Favourite :
                                                    {serviceID.includes(response.id) ?
                                                      salonLocations?.salon_sub_services?.filter(el => el.sub_sub_service_id == response.id).map((el,index) => {
                                                        return (
                                                          <>
                                                            <input type="checkbox" disabled={editingIndex != el.id} defaultChecked={el.is_fav} onChange={(e) => { setIsFav(e.target.checked) }} />
                                                          </>
                                                        )
                                                      })
                                                      :
                                                      <input type="checkbox" onChange={(e) => { console.log('favo dajko', e); setIsFav(e.target.checked) }} />
                                                    }
                                                  </div>
                                                  <div class="col-sm">
                                                    {console.log("serviceID", serviceID)}
                                                    {serviceID.includes(response.id) ?
                                                      salonLocations?.salon_sub_services?.filter(el => el.sub_sub_service_id == response.id).map(el => {
                                                        return (
                                                          <>
                                                            <div className="d-flex gap-3">
                                                              <button type="button" className="btn btn-sm btn-secondary staffButtonSalon" onClick={() => handleModalStaf(response)}>Staff</button>
                                                              {editingIndex == el.id ? (
                                                                <>
                                                                  <button className="btn btn-sm btn-success editButtonSalon" onClick={(e) => EditSalonService(sub?.id, response.id, response.name_al, e, el.id, el.is_Fav)}>save</button>
                                                                </>
                                                              ) : null}
                                                              {editingIndex == el.id ?
                                                                ""
                                                                :
                                                                <button className="btn btn-sm btn-primary addButtonSalon" onClick={() => setEditingIndex(el.id)}>Edit</button>
                                                              }
                                                              <button className="btn btn-sm btn-danger" onClick={() => handleModalDelete(el)}>Remove</button>
                                                            </div>
                                                          </>
                                                        )
                                                      })
                                                      :
                                                      <button className="btn btn-sm btn-primary addButtonSalon" onClick={() => handleAddModalStaf(sub?.id, response.id, response.name_al, value, selectedTabs[0], salonLocations.salon_id, isFav)}>Add</button>
                                                    }
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>

                </Tabs>
                <div className="line"></div>
                {/* ABOUT  */}
                <div className="container">
                  <h6 className="aboutSalon my-5">Rreth sallonit:</h6>
                  <div className="row aboutLocation">
                    <div className="col-md-6">
                      <p className="aboutSalonNameEdit mt-1">Lokacioni</p>
                      <div className="row">
                        <div className="col">
                          {/* <form onSubmit={handleMap}>
                            <div className="col-md-10 aboutSalonLocation d-flex gap-3 justify-content-start mb-2">
                              <button className="addLocation" onClick={() => handleModalAdd()}>Add Location</button>
                            </div>
                          </form> */}
                        </div>
                      </div>
                      <div className="list-group-div"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="150"
                      >
                        <ul className="list-group">
                          {console.log("salonLocations", salonLocations)}
                          {/* {salonLocations?.salon_addresses?.map((el, index) => ( */}
                          <li className="list-group-item" key={salonLocations.id}>
                            {/* <div className="row  mt-2"> */}
                            {/* <div className="searchButtons"> */}
                            <div className="col-sm-5 gap-2 d-flex searchButtons">
                              <div className="iconLocation">
                                <img src={iconLocation} className="iconLocation" alt="" />
                              </div>
                              <input className="inputLocation mb-1"
                                type="text"
                                defaultValue={salonLocations?.street_address_al}
                                onChange={(e) => setEditName(e.target.value)}
                              />
                            </div>
                          </li>
                          {/* ))} */}
                        </ul>
                      </div>
                      <strong>Working Hours</strong>
                      <ul class="list-group list-group-flush listatBreak">
                        {salonLocations?.salon_working_hours?.map((el, index) => {
                          const startTime = new Date(el.start_time);
                          const timeString = startTime.toISOString().split("T")[1].slice(0, 8);
                          const endTime = new Date(el.end_time);
                          const endtimeString = endTime.toISOString().split("T")[1].slice(0, 8);
                          const breakTime = new Date(el.brake_start);
                          const breaktimeString = breakTime.toISOString().split("T")[1].slice(0, 8);
                          const breakEnd = new Date(el.brake_end);
                          const breakendtimeString = breakEnd.toISOString().split("T")[1].slice(0, 8);;
                          return (
                            <>
                              <form onSubmit={(e) => handleEditWorkingHours(e, el)}>
                                <ul class="list-group groupList">
                                  <li class="row listText">
                                    <div className="text-danger">{el.day}</div>
                                    <div className="col-3"> <strong> Start H. </strong></div>
                                    <div className="col-3"> <strong>End H.</strong></div>
                                    <div className="col-3"> <strong>Break S.</strong></div>
                                    <div className="col-3"><strong>Break E.</strong></div>
                                  </li>
                                  <li class="list-group-item row gap-2 listGroup">
                                    <div className="timeListGroup">

                                      <input disabled={editTrigger != index} type="time" name="start_time" id="start_time" defaultValue={timeString} className="col-3 inputWorkingHours" />
                                      <input disabled={editTrigger != index} type="time" name="end_time" id="end_time" defaultValue={endtimeString} className="col-3 inputWorkingHours" />
                                      <input disabled={editTrigger != index} type="time" name="brake_start" id="brake_start" defaultValue={breaktimeString} className="col-3 inputWorkingHours" />
                                      <input disabled={editTrigger != index} type="time" name="brake_startbrake_end" id="brake_startbrake_end" defaultValue={breakendtimeString} className="col-3 inputWorkingHours" />
                                    </div>
                                    <div className="workingHours">
                                      <button className="btn btn-sm btn-edit col-2 editButtonSalon mr-0" type="button" onClick={() => setEditTrigger(index)}><strong>Edit</strong></button>
                                      <button className="btn btn-sm btn-primary col-2 addButtonSalon" type="submit" disabled={editTrigger != index}><strong>Save</strong></button>
                                      work day : <input disabled={editTrigger != index} type="checkbox" defaultChecked={el.work_this_day} name="work_this_day" id="work_this_day" />
                                    </div>
                                  </li>
                                </ul>
                              </form>
                            </>
                          )
                        })}
                      </ul>
                    </div>
                    <div className="col-md-6 ">
                      <div className="col-md-6">
                        <>
                          <h6 className="aboutSalon mt-1">Select Location Pin</h6>
                          <div style={{ height: '100vh' }}
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="150"
                          >
                            <Map name_al={editName} name_en={locationValEn} addressId={id} />
                          </div>
                        </>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mt-3 salonSchedule">
                      <p>
                        ORARI
                      </p>
                    </div>
                  </div>
                </div>
                <hr />{" "}
                <div>
                  <p>Termini - Sallonet - Prishtinë</p>
                </div>
              </div>
            </div>
            : ""}
        </Salon>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    user_data: state.data.user_data,
    sidebar: state?.data?.sidebar
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditSalonView);