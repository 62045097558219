import React from "react";
import { useState } from "react";
import Modal from "../../Modal/UserModals/Modal";
import { AddButton, TableStyle, PaginationStyle } from "../../styles/MainLayout";
import { CardStyle, AddButtonMobile } from "../../styles/MainLayoutResponsive";
import SearchInputHeader from "../SearchInputHeader";
import { connect } from "react-redux";
import { setSideBar } from "../../../redux/Functions/actions";
import ReactPaginate from 'react-paginate';
import { useEffect } from "react";
import axios from "../../../axios"
import UserDeleteModal from "../../Modal/UserModals/UserDeleteModal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const UserResponsiveTable = (props) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [dataToModal, setDataToModal] = useState(null);

  //AXIOS API
  const [salon , setSalon] = useState([])
  const getSalon = () => {
    axios.get('/api/admin/salon/all').then(
      data =>{
        console.log(data.data)
        setSalon(data.data)
      }
    ).catch(err =>{
      console.log(err)
      toast.error('failed to get salons')
    })

  }
  const [allUsers, setAllUsers] = useState([])
  const UserData = () => {
    axios.get('/api/admin/users/all').then(data => {
      console.log('allusers get req', data.data);
      setAllUsers(data.data)
      setFilteredData(data.data)
    }).catch(err => console.log(err));
  }
  const [userRoles, setUserRoles] = useState();
  const getData = () => {
    axios
      .get("/api/admin/user_roles/all")
      .then((data) => {
        console.log("allusers  roles get req", data);
        setUserRoles(data.data);
        // props.setLandData(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    UserData();
    getData();
    getSalon()
  }, [])


  // FILTER FUNCTION
  const [filteredData, setFilteredData] = useState();
  const filterByContent = async (filterValue) => {
    if (filterValue) {
      const filteredArray = allUsers.filter((el) =>
        el?.name?.toLowerCase().includes(filterValue?.toLowerCase())
      );
      setFilteredData(filteredArray);
    } else if (filterValue === "") {
      setFilteredData(allUsers);
    }
  };


  // FILTER FUNCTION

  //MODAL STATES
  const [isOpen, setIsOpen] = useState(false);
  const [singleUserData, setSingleUserData] = useState({})
  const [modalType, setModalType] = useState(null);
  //MODAL STATES

  const [viewportSize, setViewportSize] = useState(window.innerWidth); //DESKTOP VIEW & MOBILE VIEW

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(false);
  const startIndex = currentPage * 4;
  const endIndex = startIndex + 4;
  const numPages = Math.ceil(filteredData?.length / 4)

  const data = filteredData?.slice(startIndex, endIndex);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  //PAGINATION

  const handleModalAdd = () => {
    setIsOpen(true);
    setModalType("add");
  };
  const handleModalView = (data) => {
    setIsOpen(true);
    setModalType("view");
    setSingleUserData(data);
  };
  const handleModalDelete = (data) => {
    setIsOpenDelete(true);
    setModalType("delete");
    setDataToModal(data)
  }
  const handleModalClose = () => {
    setIsOpen(false);
    setIsOpenDelete(false)
  };
  window.addEventListener("resize", () => {
    setViewportSize(window.innerWidth);
  });

  if (viewportSize > 800) {
    return (
      <>
        <SearchInputHeader filterByContent={filterByContent} />
        {isOpen === true ? (
          <Modal
            getNewUsers={() => UserData()}
            data={singleUserData}
            type={modalType}
            isOpen={isOpen}
            handleModalClose={handleModalClose}
          />
        ) : (
          ""
        )}
        {isOpenDelete === true ? (
          <UserDeleteModal
            getNewUsers={() => UserData()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal={dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (

          ""
        )}
        {/* <div className="filterSelect w-100">

        <select name="filter by Role " id="" className="mr-0 ">
          <option value="" disabled selected hidden >Filter By Roles:</option>
        </select>
        </div> */}
        <TableStyle collapse={props?.sidebar}>
          
          <table class="table table-hover">
            <thead>
              <tr>
                {/* <th scope="col-1">ID</th> */}
                <th scope="col-4">Full Name</th>
                <th scope="col-3">Email</th>
                <th scope="col-3">Role </th>
                <th scope="col-3">Salon </th>
                <th scope="col-6">Phone Number</th>
                <th scope="col-3">Created_at</th>
                <th scope="col-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el) => {
                //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
                const date = new Date(el?.created_at)
                const formatDate = date.toLocaleDateString()
                return (
                  <>
                    <tr>
                      {/* <th scope="row" className="col-1">{el?.id}</th> */}
                      <td className="col-4">{el?.name + " " + el?.surname}</td>
                      <td className="col-3">{el?.email}</td>
                      <td class="col-sm-3">{userRoles?.find((elm) => elm.id == el?.role_id)?.role_al}</td>
                      {salon?.find((elm) => elm?.id == el?.salon_id)?.name_al == undefined ?
                      <td class="col-sm-5 text-danger">Customer</td>
                      :
                      <td class="col-sm-5">
                        <Link className="text-dark" to={`/salonAddresses/${el?.salon_id}`}>
                        {salon?.find((elm) => elm?.id == el?.salon_id)?.name_al}
                        </Link>
                      </td>
                      }
                      <td className="col-6">{el?.phone_number}</td>
                      <td className="col-3">{formatDate}</td>
                      <td className="col-3">
                        <div className="d-flex gap-3 align-items-baseline">
                          <Link to={`/profile/${el.id}`} state ={el}>
                            <i class="fa-regular fa-eye"/>
                          </Link>
                          <i
                            class="fa-regular fa-trash-can iconsForCursor"
                            onClick={() => handleModalDelete(el)}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </TableStyle>
        <PaginationStyle collapse={props?.sidebar}>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={numPages}
            onPageChange={handlePageChange}
            containerClassName={"pagination justify-content-center"}
            activeClassName={"active"}
          // currentPage={data.length > 7 ? true : false}
          />
        </PaginationStyle>
        <AddButton>
          <i
            type={"button"}
            className="fa-solid fa-circle-plus fa-xl"
            style={{ color: "#FF5758" }}
            onClick={() => handleModalAdd()}
          ></i>
        </AddButton>
      </>
    );
  } else
    return (
      <>
        <SearchInputHeader filterByContent={filterByContent} />
        {isOpen === true ? (
          <Modal
            getNewUsers={() => UserData()}
            data={singleUserData}
            isOpen={isOpen}
            type={modalType}
            handleModalClose={handleModalClose}
          />
        ) : (
          ""
        )}
        {isOpenDelete === true ? (
          <UserDeleteModal
            getNewUsers={() => UserData()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal={dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (
          ""
        )}
        <div className="container">
          {data?.map((el) => {
            //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
            const date = new Date(el?.created_at)
            const formatDate = date.toLocaleDateString()
            return (
              <>
                <CardStyle>
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-8">
                        <ul class="list-group gap-3 pt-2 pb-2 ps-3">
                          <li class="list-group"><strong>Full Name :</strong> {el?.name} {el.surname}</li>
                          <li class="list-group"><strong>Email :</strong> {el?.email}</li>
                          <li class="list-group"><strong>phone number:</strong> {el?.phone_number}</li>
                          <li class="list-group"><strong>Created_at :</strong> {formatDate}</li>
                          <li class="list-group"><strong>Role :</strong> { userRoles?.find((elm) => elm.id == el?.role_id)?.role_al}</li>
                        </ul>
                      </div>
                      <div className="col-3 pt-2">
                        <div className="d-flex gap-3">
                          <Link to={`/profile/${el.id}`}>
                            <i
                              class="fa-regular fa-eye  pt-2"
                            />
                          </Link>
                          <i
                            class="fa-regular fa-trash-can pt-2 "
                            onClick={() => handleModalDelete(el)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardStyle>
              </>
            );
          })}
          <AddButtonMobile onClick={() => handleModalAdd()}>
            <i
              class="fa-solid fa-circle-plus d-flex flex-row-reverse fa-xl"
              style={{ color: "#FF5758" }}
            />
          </AddButtonMobile>
        </div>
      </>
    );
};
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar,
    dataFilter: state?.data?.dataFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserResponsiveTable);
