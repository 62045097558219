import React, { useState } from "react";
import {Label, Input, FormTitle } from "../../styles/MainLayout";
import axios from "../../../axios";
import { useParams } from "react-router-dom";

export const WorkingHoursAdd = (props) => {
  const { id } = useParams();
  const [daysData, setDaysData] = useState([]);
  const [currentDay, setCurrentDay] = useState(0);
  const [initialDaysData, setInitialDaysData] = useState([]);

  const addDay = () => {

    const dayOfWeek = getDayOfWeek(currentDay);
    setDaysData([...daysData, { salon_address_id: id, day: dayOfWeek , work_this_day : 0 }]);
    setCurrentDay(currentDay + 1);
  };

  const getDayOfWeek = (dayIndex) => {
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday'];
    return daysOfWeek[dayIndex % 7];
  };
  console.log("daysData",daysData)

  const handleInputChange = (index, field, value) => {
    const updatedData = [...daysData];
   
      updatedData[index] = {
        ...updatedData[index],
        [field]: `2023-05-05T${value}`,
    }
    setDaysData(updatedData);
  };

  const handleDayOffClick = (index) => {
    addDay()
    const updatedData = [...daysData];
    const updatedInitialData = [...initialDaysData];
    if (updatedData[index]?.work_this_day === '1') {
      updatedData[index] = updatedInitialData[index];
    } else {
      updatedInitialData[index] = updatedData[index];
      updatedData[index] = {
        work_this_day:'1',
        start_time: '0000-00-00 00:00:00',
        end_time: '0000-00-00 00:00:00',
        brake_start: '0000-00-00 00:00:00',
        brake_end: '0000-00-00 00:00:00',
        salon_address_id:id
      };
    }
    setDaysData(updatedData);
    setInitialDaysData(updatedInitialData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (daysData.length > 7 || daysData.length < 7) {
      console.log("blocked");
    } else {
      console.log(daysData)
      axios.post('/api/admin/salon_working_hours/new_agenda/', daysData)
      .then(data => {
        console.log(data.data);
      })
      .catch(err => {
        console.log(err);
      });
    }
  };

  return (
    <>
      <FormTitle className="d-flex justify-content-center mt-3">Working Hours For: el.address</FormTitle>
      <form onSubmit={handleSubmit}>
      {daysData.map((dayData, index) => (
        <div key={index}>
        <h4>Day: {getDayOfWeek(index)}</h4>
  
        <br />
        <Label>Start Time:</Label>
        <Input
          type="time"
          // value={dayData?.start_time || ''}
          onChange={(e) => handleInputChange(index, 'start_time', e.target.value)}
          disabled={dayData?.start_time === '0000-00-00 00:00:00'}
          required={dayData?.start_time != '0000-00-00 00:00:00'}

        />
  
        <Label>End Time:</Label>
        <Input
          type="time"
          onChange={(e) => handleInputChange(index, 'end_time', e.target.value)}
          disabled={dayData?.end_time === '0000-00-00 00:00:00'}
          required={dayData?.end_time != '0000-00-00 00:00:00'}
          
        />
  
        <Label>Break Start:</Label>
        <Input
          type="time"
          onChange={(e) => handleInputChange(index, 'brake_start', e.target.value)}
          disabled={dayData?.brake_start === '0000-00-00 00:00:00'}
          required={dayData?.brake_start != '0000-00-00 00:00:00'}
        />
  
        <Label>Break End:</Label>
        <Input
          type="time"
          onChange={(e) => handleInputChange(index, 'brake_end', e.target.value)}
          disabled={dayData?.brake_end === '0000-00-00 00:00:00'}
          required={dayData?.brake_end != '0000-00-00 00:00:00'}
        />
  
        <button type="button" onClick={() => handleDayOffClick(index)}>
          {dayData?.work_this_day === '1' ? 'Cancel' : 'Day Off'}
        </button>
      </div>
    ))}
        <button className="btn btn-sm btn-primary" disabled={daysData.length >= 7} type="button" onClick={addDay}>
          Add Day ({getDayOfWeek(currentDay)})
        </button>
        <button className="btn btn-sm btn-success ms-3" type="submit" disabled={daysData.length < 7}>Submit</button>
          </form>
    </>
  );
};
