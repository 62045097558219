import React, { useEffect, useState } from 'react'
import SearchInputHeader from "../SearchInputHeader";
import { AddButton, TableStyleLanguage, PaginationStyle, ImageForServices } from "../../styles/MainLayout";
import ReactPaginate from 'react-paginate';
import { CardStyle, AddButtonMobile } from "../../styles/MainLayoutResponsive";
import axios from "../../../axios"
import ServiceModal from "../..//Modal/ServiceModal"
import ServiceDeleteModal from '../../Modal/ServiceDeleteModal';
import { connect } from "react-redux";
import { setSideBar } from "../../../redux/Functions/actions";
import "./services.scss"
import { Link } from 'react-router-dom';

const AllServices = (props) => {
  const [viewportSize, setViewportSize] = useState(window.innerWidth); //DESKTOP VIEW & MOBILE VIEW
  window.addEventListener("resize", () => {
    setViewportSize(window.innerWidth);
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal, setDataToModal] = useState(null);

  //AXIOS
  const [services, setServices] = useState([])
  const getServices = () => {
    axios.get('/api/admin/services/all_ser').then(
      data => {
        console.log("serviset", data.data)
        setServices(data.data)
        setFilteredData(data.data)

      }
    ).catch(
      err => {
        console.log(err)
      }
    )
  }

  useEffect(() => {
    getServices();
  }, [])
  //AXIOS

  const [filteredData, setFilteredData] = useState();
  const filterByContent = async (filterValue) => {
    if (filterValue) {
      const filteredArray = services.filter((el) =>
        el?.name_al?.toLowerCase().includes(filterValue?.toLowerCase())
      );
      setFilteredData(filteredArray);
    } else if (filterValue == "") {
      setFilteredData(services);
    }
  };


  //PAGINATION
  const [currentPage, setCurrentPage] = useState(false);
  const startIndex = currentPage * 5;
  const endIndex = startIndex + 5;
  const numPages = Math.ceil(filteredData?.length / 5)
  const data = filteredData?.slice(startIndex, endIndex);
  console.log('data e mire', data)
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  //PAGINATION

  const handleModalAdd = () => {
    setIsOpen(true);
    setModalType("add");
  };
  const handleModalEdit = (data) => {
    setIsOpen(true);
    setModalType("edit");
    setDataToModal(data)
  };
  const handleModalClose = () => {
    setIsOpen(false);
    setIsOpenDelete(false)
  }
  const handleModalDelete = (data) => {
    setIsOpenDelete(true);
    setModalType("delete");
    setDataToModal(data)
  }

  if (viewportSize > 800) {
    return (
      <>

        <SearchInputHeader
          filterByContent={filterByContent}
        />
        {isOpen === true ? (
          <ServiceModal
            getNewServices={getServices}
            isOpen={isOpen}
            type={modalType}
            dataToModal={dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (
          ""
        )}
        {isOpenDelete === true ? (
          <ServiceDeleteModal
            getNewServices={() => getServices()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal={dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (

          ""
        )}
        <TableStyleLanguage collapse={props?.sidebar}>
          <table class="table table-hover service_layout">
            <thead>
              <tr>
                <th scope="col-1">ID</th>
                <th scope="col-1">Icon</th>
                <th scope="col-3">Serviset Al</th>
                <th scope="col-3">Serviset En</th>
                <th scope="col-3">
                  {/* Services */}
                </th>
                <th scope="col-3 ">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el) => {
                return (
                  <tr>
                    <td className="col-3">{el?.id}</td>
                    <td scope="row" className="col-3">

                      <img className="img-fluid service_image" src={`${process.env.REACT_APP_API}/${el.img_url}`} />

                    </td>
                    <td className="col-4">{el?.name_al}</td>
                    <td className="col-4">
                      {el?.name_en}
                    </td>
                    <td className="col-4">
                    </td>
                    <td className="col-2">
                      <div className="d-flex gap-3">
                        <Link class="fa-regular fa-eye eyeStyle" to={`/services/${el?.id}`} />
                        <i class="fa-regular fa-pen-to-square iconsForCursor" onClick={() => handleModalEdit(el)} />
                        <i class="fa-regular fa-trash-can iconsForCursor" onClick={() => handleModalDelete(el)} />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </TableStyleLanguage>
        <PaginationStyle collapse={props?.sidebar}>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={numPages}
            onPageChange={handlePageChange}
            containerClassName={"pagination justify-content-center"}
            activeClassName={"active"}
          />
        </PaginationStyle>
        <AddButton >
          <i
            type={"button"}
            className="fa-solid fa-circle-plus fa-xl addButtonModalLanguage"
            onClick={() => handleModalAdd()}>
          </i>
        </AddButton>
      </>
    )
  } else
    return (
      <>
        <SearchInputHeader
          filterByContent={filterByContent}
        />
        {isOpen === true ? (
          <ServiceModal
            getNewServices={() => getServices()}
            isOpen={isOpen}
            type={modalType}
            dataToModal={dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (
          ""
        )}
        {isOpenDelete === true ? (
          <ServiceDeleteModal
            getNewServices={() => getServices()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal={dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (

          ""
        )}

        <div className="container">
          {services?.map((el) => {
            console.log('elementet e mira', el)
            return (
              <>
                <CardStyle>
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-8 iconServices ">
                        {/* <div className="iconServices"> */}

                        <ul class="list-group gap-3 pt-3 pb-2 ps-3">
                          {/* <li class="list-group">Id: {el?.id}</li> */}
                          <li class="list-group">
                            <img className="img-fluid service_imageRes" src={`${process.env.REACT_APP_API}/${el.img_url}`} />
                          </li>
                          <hr className='m-0 w-100' />
                          <li class="list-group"><strong>Serviset:</strong>  {el?.name_al}</li>
                          <li class="list-group"><strong>Services:</strong>  {el?.name_en}</li>
                        </ul>
                      </div>
                      <div className="col-3 pt-2 ">
                        <div className="d-flex gap-2">
                          <Link class="fa-regular fa-eye eyeStyle" to={`/services/${el?.id}`} />
                          <i class="fa-regular fa-pen-to-square iconsForCursor" onClick={() => handleModalEdit(el)} />
                          <i class="fa-regular fa-trash-can iconsForCursor" onClick={() => handleModalDelete(el)} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </CardStyle>
              </>
            )
          })}
          <AddButtonMobile>
            <i
              class="fa-solid fa-circle-plus d-flex flex-row-reverse fa-2xl addButtonLanguageMobile"
              onClick={() => handleModalAdd()}
            />
          </AddButtonMobile>
        </div>
      </>
    );
}

const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar,
    dataFilter: state?.data?.dataFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllServices);