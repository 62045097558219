import React from "react";
import "./addSalons.scss"
import { useState } from "react";
import { BsPen } from "react-icons/bs"
import Aos from "aos";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { Salon, SelectOption } from "../../../components/styles/MainLayout"
import SearchInputHeader from "../../../components/form/SearchInputHeader";
import { setSideBar } from "../../../redux/Functions/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const AddSalonView = (props) => {
  const [showServices, setShowServices] = useState(true)
  // const [showMap, setShowMap] = useState(false)
  const [staticName, setStaticName] = useState("Salon Name")
  const [imageUrl, setImageUrl] = useState()
  const [salonId, setSalonID] = useState()
  const [selectedImage, setSelectedImage] = useState(null);
  const [owner, setOwner] = useState([])
  const [Membership, setMembership] = useState([])


  Aos.init();
  //API 
  const getMembership = () => {
    axios
      .get("/api/admin/subscriptions/all")
      .then((response) => {
        console.log("olaLabels", response.data);
        setMembership(response.data)

      }).catch(err => {
        console.log(err)
      })
  }
  const UserData = () => {
    axios.get('/api/admin/users/storeAdmins').then(data => {
      console.log('all salon owners get req', data.data);
      setOwner(data.data)
    }).catch(err => console.log(err));
  }
  const navigate = useNavigate()
  const AddSalon = (e) => {
    e.preventDefault();
    let data = {
      owner_id: e.target.elements.owner_id.value,
      subscription_id: e.target.elements.subscription_id.value,
      name_al: e.target.elements.name_al.value,
      name_en: e.target.elements.name_al.value,
      desc_al: e.target.elements.desc_al.value,
      desc_en: e.target.elements.desc_en.value,
      created_at: new Date(),
      created_by: props.user_data.id
    };
    axios.post("/api/admin/salon", data)
      .then(response => {
        console.log("response", response)
        toast.success("Salon added successfully");
        toast.info("You may continue to add the services of the salon or locations");
        setShowServices(true);
        const lastID = response?.data?.salon_data.id
        setSalonID(lastID)
        // const formData = new FormData();
        // formData.append('salon_id', lastID);
        // formData.append('img_url', imageUrl);
        // formData.append('img_alt', "Salon image");
        //     axios.post(`/api/admin/salon_images`, formData).then(
        //       data => {
        //         console.log("image shkoj", data.data)
        //       }
        //       ).catch(err => {
        //         console.log(err)
        //         toast.error("image Didnt upload")
        //       })
        navigate(`/salonAddresses/${lastID}`)
        // window.location.href = `/editsalon/${lastID}`;
      })
      .catch(err => {
        console.log(err);
      });
  };
  console.log("salon id:", imageUrl);
  //API
  //Functions 
  const handleImageUpload = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage(reader.result);
    };

    if (e) {
      reader.readAsDataURL(e);
    }
  };
  useEffect(() => {
    UserData()
    getMembership()
  }, [])
  return (
    <>
      <SearchInputHeader />
      <div className="container bgSalon">
        <Salon collapse={props?.sidebar}>
          <p className="salonName" role="button">{staticName} <BsPen style={{ fontSize: '20px', marginBottom: '20px' }} /></p>
          <form onSubmit={AddSalon}>
            <div class="container"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="150"
            >
              <div class="mb-5">
                <div class="col-md-3 col-12 align-self-start">
                  <input required className="form-control" type="text" name="name_al" id="name_al" placeholder="Emri Sallonit" />
                </div>
                {/* <div class="col-3 mt-3 align-self-center">
                  <input required className="form-control" type="text" name="name_en" id="name_en" placeholder="Salon Name" />
                </div> */}
              </div>
            </div>
            {/* <div className="row">
            <div className="col-md-6">
              <p className="mt-2">Choose Salon Image</p>
            </div>
            <div className="border pt-5 pb-5 chooseFile">
              <label className="custom-file-upload">
         <div className="iconUploadImg">
         <img className="iconUpload mb-4" src={selectedImage === null ? IconUpload: selectedImage} />
         </div>
              <input required type="file" name="img_url" id="img_url" onChange={(e) => {setImageUrl(e.target.files[0]);handleImageUpload(e.target.files[0])} } />
              </label>
            </div>
          </div> */}
            <div className="row">
              <div className="col-12 col-md-6">
                <p className="salonName" role="button">Description <BsPen style={{ fontSize: '20px', marginBottom: '20px' }} /></p>
                <div class="container"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="150"
                >
                  <div class=" row mb-5">
                    <div class="col align-self-start">
                      <textarea required className="form-control" name="desc_al" id="desc_al" placeholder="Pershkrimi Sallonit" cols="50" rows="7"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">

                <p className="salonName" role="button">Description <BsPen style={{ fontSize: '20px', marginBottom: '20px' }} /></p>
                <div class="container"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="150"
                >
                  <div class=" row mb-5">
                    <div class="col align-self-start">
                      <textarea required className="form-control" name="desc_en" id="desc_en" placeholder="Description of Salon" cols="50" rows="7"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SelectOption id="owner_id" name="owner_id" required>
              <option selected disabled>--select owner--</option>
              {owner?.map(el => {
                return <option value={el?.id} >{`${el?.name}  ${el?.surname} `}   </option>
              })}
            </SelectOption>
            <SelectOption id="subscription_id" name="subscription_id" required>
              <option selected disabled>--select subscription--</option>
              {Membership?.map(el => {
                return <option value={el?.id}>{el?.name}</option>
              })}
            </SelectOption>
            <div className="slideShowSalon mt-5">
              <button className="btn btn-md btn-success mb-2" type="submit">Save</button>

            </div>
          </form>

          {/* SHEBRIMET */}

          {/* THINGS to CHANGE:
        -remove d-flex from divs
        - style all the divs, and elements
        -reponsivity
      -add other elements */}
          <div className="row">
            <div className="line"></div>
            <div className="container services">
              {/* ABOUT  */}
              <div className="container">
              </div>
              <div>
                <p>Termini - Sallonet </p>
              </div>
            </div>
          </div>
        </Salon>
      </div>
    </>

  )
}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    sidebar: state?.data?.sidebar,
    user_data: state?.data?.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddSalonView);