import React, { useEffect, useState } from "react";
import {
    LabelProfile, ProfileBody, ProfileForm,
    ProfileContent, ImageProfile, ButtonatPorfilittt,
    ModalGenderButtonProfile, HeadingRole, LabelProfileButton,
    SaveButtonProfile, Role, ModalActiveButtonProfile, ProfilePic,
    ProfileText, ProfileImage, ProfileImageText, ProfileButton,
    FormControl, Input, ButtonGender, SelectOption, SaveButtonProfileGreen, OldPassword,
    Password, ProfileFormPassword, EditProfileIcon, DivForLabelInput, InputProfileForm,
    LabelProfileGender, DivForLabelAndInputLang, ChangePassword, DivForLabelInputPass,
    SaveButtonDiv, SavePasswordButtonProfile
} from "../../styles/MainLayout";
import SearchInputHeader from "../SearchInputHeader";
import { Button } from "antd";
import Photo from "../../../assets/images/profile-man.svg"
import Photo1 from "../../../assets/images/img_530033.png"
import { setSideBar, setUserData } from "../../../redux/Functions/actions";
import { connect } from "react-redux";
import axios from '../../../axios';
import { BsToggleOn, BsToggleOff } from "react-icons/bs"
import "./profile.scss"
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import sign from "jwt-encode";

const Profile = (props) => {
    const [users, setUser] = useState({});
    const [membershipData, setGetMembershipData] = useState([])
    const [gender, setGender] = useState()
    const [editTrigger, setEditTrigger] = useState(false);
    const [wheditTrigger, setWhEditTrigger] = useState(null);
    const [passeditTrigger, setPassEditTrigger] = useState(false);
    const [active, setActive] = useState();
    const [defaultlanguage, setDefaultLanguage] = useState(1)
    const [salonData, setSalonData] = useState()
    const [addressData, setAddressData] = useState([])
    const { pid } = useParams()
    const [showAvatar, setShowAvatar] = useState(true);
    const [showServices, setShowServices] = useState(false);
    const [showWorkingHours, setShowWorkingHours] = useState(false);
    const [workingHoursData, setWorkingHoursData] = useState([])
    const [userServices, setUserServices] = useState([])
    const [profileActive, setProfileActive] = useState(1)

    const location = useLocation();
    const propsData = location.state;
    console.log("propsData", propsData);
    console.log("Hej", props?.user_data?.salon_id)
    console.log("HejDion", props)
    const secretKey = process.env.REACT_APP_SECRET_KEY


    const getUserData = () => {
        axios.get(`/api/admin/users/single/${pid}`)
            .then(response => {
                console.log("userData", response?.data);
                setUser(response?.data)
                setGender(response?.data?.gender)
            }).catch(err => {
                console.log(err)
            })
    }
    const getMembership = () => {
        axios.get("/api/admin/subscriptions/all")
            .then((response) => {
                console.log("olaLabels", response?.data);
                setGetMembershipData(response?.data)

            }).catch(err => {
                console.log(err)
            })
    }

    //AXIOS (Get all genders)
    const [allGenders, setAllGenders] = useState([]);
    const [selectedGender, setSelectedGender] = useState();
    const getAllGenders = () => {
        axios
            .get("/api/admin/genders/all")
            .then((response) => {
                console.log("gender", response.data);
                setAllGenders(response.data)

            }).catch(err => {
                console.log(err)
            })
    }

    //AXIOS

    const getAddress = async () => {
        await axios.get(`/api/admin/salon_address/all_addresses/${propsData?.salon_id}`)
            .then((response) => {
                console.log("olaLabels", response?.data);
                setAddressData(response?.data)

            }).catch(err => {
                console.log(err)
            })
    }
    const getUserWorkingHours = (e) => {
        axios.get(`/api/admin/worker_working_hours/${pid}`).then(
            data => {
                console.log(data.data)
                setWorkingHoursData(data.data)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }

    const getUserServices = () => {
        axios.get(`/api/admin/user_services/singleWorkerServices/${pid}`).then(
            data => {
                console.log(data.data)
                setUserServices(data.data)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }

    console.log("userServices", userServices)
    const handleEdit = (e) => {
        e.preventDefault();
        // let passEnc = sign(e.target.password?.value, secretKey)
        let editBody = {
            id: users?.id,
            name: e?.target?.first_name?.value,
            surname: e?.target?.last_name?.value,
            email: e?.target?.email?.value,
            username: e?.target?.username?.value,

            // password : passEnc,
            salon_id: users?.salon_id,
            gender: selectedGender,
            phone_number: e?.target?.phone_number?.value,
            role_id: users?.role_id,
            subscription_id: e?.target?.subscription_id?.value,
            default_language: defaultlanguage,
            salon_address_id: e?.target?.address_id?.value
        }
        // console.log("api per register" , editBody)
        axios.put(`/api/admin/users/update`, editBody)
            .then((response) => {
                toast.success("Label edited sucessfully.")
                getUserData();
                setEditTrigger(false)
            }).catch(err => {
                toast.error("Something Went Wrong")
                console.log(err)
            })
    };
    const getSingleSalon = (id) => {
        axios.get(`api/admin/salon/single/${propsData?.salon_id}`)
            .then(res => {
                setSalonData(res?.data)
                console.log('res.data.salon', res?.data)
            })
            .catch(error => console.log("error geting salon data", error))
    }



    const handleEditPassword = (e) => {
        e.preventDefault()
        let passEnc = sign(e.target.old_password?.value, secretKey)
        let NewpassEnc = sign(e.target.new_password?.value, secretKey)

        let editBody = {
            email: users?.email,
            old_password: passEnc,
            new_password: NewpassEnc
        }
        // console.log("api per register", editBody)
        axios
            .put(`/api/admin/users/change_pass`, editBody)
            .then((response) => {
                console.log("olaLabels", response);
                toast.success("Password changed sucessfully.")
            }).catch(err => {
                toast.error("Old Password does not match")
                console.log(err)
            })
    };


    const handleAvatarClick = () => {
        setShowAvatar(true);
        setShowServices(false);
        setShowWorkingHours(false);
        setProfileActive(1);
    };

    const handleServicesClick = () => {
        setShowAvatar(false);
        setShowServices(true);
        setShowWorkingHours(false);
        setProfileActive(2);
        getUserServices();

    };

    const handleWorkingHoursClick = () => {
        setShowAvatar(false);
        setShowServices(false);
        setShowWorkingHours(true);
        getUserWorkingHours()
        setProfileActive(3);
    };
    const handleEditWorkingHours = (e, el) => {
        e.preventDefault()
        console.log('CHECK', e.target.work_this_day.checked)
        let body = {
            id: el.id,
            start_time: e.target.start_time.value != el?.start_time.slice(11, 19) ? `2023-05-27T${e.target.start_time.value}:00.000Z` : null,
            end_time: e.target.end_time.value != el?.end_time.slice(11, 19) ? `2023-05-27T${e.target.end_time.value}:00.000Z` : null,
            brake_start: e.target.brake_start.value != el?.brake_start.slice(11, 19) ? `2023-05-27T${e.target.brake_start.value}:00.000Z` : null,
            brake_end: e.target.brake_startbrake_end.value != el?.brake_end.slice(11, 19) ? `2023-05-27T${e.target.brake_startbrake_end.value}:00.000Z` : null,
            work_this_day: `${e.target.work_this_day.checked}`
        }
        console.log("edit wh", body)
        axios.put("/api/admin/worker_working_hours/update_agenda", body).then(data => {
            console.log(data.data)
            toast.success("Working Hours Updated!")
        }
        ).catch(err => {
            console.log(err)
            toast.error("something went wrong")
        })
    }
    useEffect(() => {
        getUserData();
        getAddress();
        getMembership();
        getSingleSalon();
        getAllGenders();


    }, [])


    let servicesData = userServices?.user_service?.sub_sub_services.map(el => { return el?.name_al })

    return (
        <>
            <SearchInputHeader />
            <ProfileBody >
                <ProfileContent collapse={props?.sidebar}>
                    {/* <ProfilePic> */}
                    {/* <Role>
                            {users?.role_id == 1 ?
                                <HeadingRole>SUPER ADMIN</HeadingRole> :
                                users?.role_id == 2 ?
                                    <HeadingRole>STORE ADMIN OF '{salonData?.name_al}' Salon</HeadingRole>
                                    : users?.role_id == 4 ?
                                        < HeadingRole > STAF OF '{salonData?.name_al}' Salon</HeadingRole>
                                        : users?.role_id == 5 ?
                                            < HeadingRole > COSTUMER </HeadingRole>
                                            : ""
                            }
                        </Role> */}


                    <ProfilePic>
                        {users.role_id == 4 ?
                            <ProfileButton className="mb-2">
                                <Button onClick={() => handleAvatarClick()} className={profileActive === 1 ? "border border-primary" : ""}>Avatar</Button>
                                <Button onClick={() => handleServicesClick()} className={profileActive === 2 ? "border border-primary" : ""}>Services</Button>
                                <Button onClick={() => handleWorkingHoursClick()} className={profileActive === 3 ? "border border-primary" : ""}>Working H.</Button>
                            </ProfileButton>
                            :
                            ""
                        }
                        {showServices == true ?
                            <>
                                {userServices?.user_services && userServices.user_services.length > 0 ? (
                                    <Role>
                                        <HeadingRole>Your Services :</HeadingRole>
                                        <ul class="list-group list-group-flush">
                                            {userServices.user_services.map(elm => (
                                                <li class="list-group-item">
                                                    {elm?.sub_sub_services?.map(elem => elem?.name_al)}
                                                </li>
                                            ))}
                                        </ul>
                                    </Role>
                                ) : (
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">There is no Service</li>
                                    </ul>
                                )}


                            </>
                            : showAvatar == true ?
                                <>
                                    <Role>
                                        {users.role_id == 1 ?
                                            <HeadingRole>SUPER ADMIN</HeadingRole> :
                                            users.role_id == 2 ?
                                                <HeadingRole>STORE Admin OF '{salonData?.name_al}'</HeadingRole>
                                                : users.role_id == 4 ?
                                                    < HeadingRole > STAF OF '{salonData?.name_al}' Salon</HeadingRole>
                                                    : users.role_id == 5 ?
                                                        < HeadingRole > COSTUMER </HeadingRole>
                                                        : ""
                                        }
                                    </Role>
                                    <ProfileImage>
                                        {users.gender == 1 ?
                                            <ImageProfile src={Photo} alt="" />
                                            :
                                            <ImageProfile src={Photo1} alt="" />
                                        }
                                    </ProfileImage>

                                </>
                                :
                                showWorkingHours == true ?
                                    <>
                                        <Role>
                                            <HeadingRole>Your Working Hours :</HeadingRole>
                                        </Role>
                                        <ul class="list-group list-group-flush listatBreak">
                                            {workingHoursData?.map((el, index) => {
                                                const startTime = new Date(el.start_time);
                                                const timeString = startTime.toISOString().split("T")[1].slice(0, 8);
                                                const endTime = new Date(el.end_time);
                                                const endtimeString = endTime.toISOString().split("T")[1].slice(0, 8);
                                                const breakTime = new Date(el.brake_start);
                                                const breaktimeString = breakTime.toISOString().split("T")[1].slice(0, 8);
                                                const breakEnd = new Date(el.brake_end);
                                                const breakendtimeString = breakEnd.toISOString().split("T")[1].slice(0, 8);
                                                return (
                                                    <>
                                                        <form onSubmit={(e) => handleEditWorkingHours(e, el)}>
                                                            <ul class="list-group groupList">
                                                                <li class="row listText">
                                                                    <div className="text-danger">{el.day}</div>
                                                                    <div className="col-3"> <strong> Start H. </strong></div>
                                                                    <div className="col-3"> <strong>End H.</strong></div>
                                                                    <div className="col-3"> <strong>Break S.</strong></div>
                                                                    <div className="col-3"><strong>Break E.</strong></div>
                                                                </li>
                                                                <li class="list-group-item row gap-2 listGroup">
                                                                    <div className="timeListGroup">
                                                                        <input disabled={wheditTrigger != index} type="time" name="start_time" id="start_time" defaultValue={timeString} className="col-3 inputWorkingHours" />
                                                                        <input disabled={wheditTrigger != index} type="time" name="end_time" id="end_time" defaultValue={endtimeString} className="col-3 inputWorkingHours" />
                                                                        <input disabled={wheditTrigger != index} type="time" name="brake_start" id="brake_start" defaultValue={breaktimeString} className="col-3 inputWorkingHours" />
                                                                        <input disabled={wheditTrigger != index} type="time" name="brake_startbrake_end" id="brake_startbrake_end" defaultValue={breakendtimeString} className="col-3 inputWorkingHours" />
                                                                    </div>

                                                                    {users.role_id == 4 ?
                                                                        <>
                                                                            <div className="workingHours">

                                                                                <div className="workingHours">
                                                                                    <button className="btn btn-sm btn-edit col-2 editButtonSalon mr-0" type="button" onClick={() => setWhEditTrigger(index)}><strong>Edit</strong></button>
                                                                                    <button className="btn btn-sm btn-primary col-2 addButtonSalon" type="submit" disabled={wheditTrigger != index}><strong>Save</strong></button>
                                                                                    work day : <input disabled={wheditTrigger != index} type="checkbox" defaultChecked={el.work_this_day} name="work_this_day" id="work_this_day" />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        ""
                                                                    }


                                                                </li>
                                                            </ul>
                                                        </form>
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </>
                                    :
                                    ""
                        }

                        {/* </ProfilePic> */}


                        {/* <ProfileImage>
                            {users?.gender == 1 ?
                                <ImageProfile src={Photo} alt="" />
                                :
                                <ImageProfile src={Photo1} alt="" />
                            }
                        </ProfileImage>
                        <ProfileButton>
                            <Button>Change</Button>
                            <Button>Save</Button>
                        </ProfileButton> */}
                    </ProfilePic>
                    <ProfileText>
                        <ProfileForm onSubmit={handleEdit}>
                            <ProfileImageText
                            >{users?.name}'s profile
                            </ProfileImageText>
                            <EditProfileIcon>
                                <i class=" fa-regular fa-pen-to-square editi iconsForCursor" onClick={() => setEditTrigger(!editTrigger)} />
                            </EditProfileIcon>
                            <FormControl className="olaFormControl">
                                <DivForLabelInput className="forDisable" disabled={!editTrigger} >
                                    <LabelProfile >Name</LabelProfile>
                                    <InputProfileForm trigger={editTrigger} disabled={editTrigger == false} className={editTrigger === false ? "inputProfile" : ""} name="first_name" id="first_name" defaultValue={users?.name} type="text" />
                                </DivForLabelInput>

                                <DivForLabelInput className="forDisable" disabled={!editTrigger} >
                                    <LabelProfile >Surname</LabelProfile>
                                    <InputProfileForm trigger={editTrigger} disabled={editTrigger == false} className={editTrigger === false ? "inputProfile" : ""} name="last_name" id="last_name" defaultValue={users?.surname} type="text" />
                                </DivForLabelInput>

                                <DivForLabelInput className="forDisable" disabled={!editTrigger}>
                                    <LabelProfile >Username</LabelProfile>
                                    <InputProfileForm trigger={editTrigger} disabled={editTrigger == false} className={editTrigger === false ? "inputProfile" : ""} required id="username" name="username" defaultValue={users?.username} placeholder="Username" type="text" />
                                </DivForLabelInput>

                                <DivForLabelInput className="forDisable" disabled={!editTrigger}>
                                    <LabelProfile> Email </LabelProfile>
                                    <InputProfileForm trigger={editTrigger} disabled={editTrigger == false} className={editTrigger === false ? "inputProfile" : ""} name="email" id="email" defaultValue={users?.email} type="email" />
                                </DivForLabelInput>


                                <DivForLabelInput className="forDisable" disabled={!editTrigger}>
                                    <LabelProfile> Phone number </LabelProfile>
                                    <InputProfileForm trigger={editTrigger} disabled={editTrigger == false} className={editTrigger === false ? "inputProfile" : ""} name="phone_number" id="phone_number" defaultValue={users?.phone_number} type="number" />
                                </DivForLabelInput>
                                <div className="row">

                                    <div className="col-12 col-md-6">



                                        <DivForLabelAndInputLang className="forDisable">
                                            <LabelProfile> Gender </LabelProfile>
                                            {console.log("users", users)}
                                            <SelectOption className="mt-0" required id="default_language" name="default_language" onChange={(event) => setSelectedGender(event.target.value)} value={selectedGender || users.gender}>
                                                {allGenders.map(gender => (
                                                    <option key={gender.id} value={gender.id}>{gender.name_al}</option>
                                                ))}
                                            </SelectOption>
                                        </DivForLabelAndInputLang>
                                    </div>
                                    {/* {users.role_id == 1 ?
                                ""
                                : 
                                <>
                                {users?.is_active == active ?
                                    <ModalActiveButtonProfile disabled={editTrigger == false} className={editTrigger === 2 ? "bg-light" : ""} type={"button"} onClick={() => setActive(0)}>Is Active:
                                        <BsToggleOff size={30} />
                                    </ModalActiveButtonProfile>
                                    :
                                    <ModalActiveButtonProfile disabled={editTrigger == false} className={editTrigger === 1 ? "" : ""} type={"button"} onClick={() => setActive(1)}>Is Active:
                                        <BsToggleOn size={30} className="ola" />
                                    </ModalActiveButtonProfile>
                                }
                                </>
                                } */}
                                    <div className="col-12 col-md-6">

                                        <DivForLabelAndInputLang className="forDisable">
                                            <LabelProfile> Language </LabelProfile>

                                            <SelectOption className="mt-0" required id="default_language" name="default_language" defaultValue={users?.default_language}>
                                                <option value={1}>Shqip</option>
                                                <option value={2}>English</option>
                                            </SelectOption>
                                        </DivForLabelAndInputLang>
                                    </div>

                                </div>

                                {users.role_id == 1 ?
                                    ""
                                    :
                                    <>
                                        {props?.data?.user_data?.user_id == 4 || users.role_id == 2 ?
                                            <DivForLabelAndInputLang className="forDisable">
                                                <LabelProfile> Salon Address</LabelProfile>

                                                <SelectOption disabled={editTrigger == false} required id="address_id" name="address_id" defaultValue={users?.address_id}>
                                                    <option hidden value={users?.salon_address_id}>
                                                        {addressData?.find(el => el?.id == users?.salon_address_id)?.name_al}
                                                    </option>
                                                    {addressData?.map(el => {
                                                        return <option value={el?.id}>{el?.name_al}</option>
                                                    })}
                                                </SelectOption>
                                            </DivForLabelAndInputLang>
                                            :
                                            ""
                                        }
                                    </>
                                }
                            </FormControl>

                            {editTrigger == false ?
                                ""
                                :

                                <SaveButtonProfileGreen disabled={editTrigger == false}>Save</SaveButtonProfileGreen>

                            }
                        </ProfileForm>
                        <ChangePassword>
                            <LabelProfileButton type={"button"} onClick={() => setPassEditTrigger(!passeditTrigger)}>Change Password</LabelProfileButton>
                            {passeditTrigger == false ?
                                ""
                                :
                                <ProfileFormPassword onSubmit={handleEditPassword}>
                                    <Password>
                                    </Password>
                                    <DivForLabelInputPass>

                                        <OldPassword>Old Password</OldPassword>
                                        <InputProfileForm required id="old_password" name="old_password" placeholder="*****" type="password" />
                                    </DivForLabelInputPass>

                                    <DivForLabelInputPass>

                                        <OldPassword>New Password</OldPassword>
                                        <InputProfileForm required id="new_password" name="new_password" placeholder="*****" type="password" />
                                    </DivForLabelInputPass>

                                    <SaveButtonDiv>

                                        <SavePasswordButtonProfile type={"submit"}>Save</SavePasswordButtonProfile>
                                    </SaveButtonDiv>
                                </ProfileFormPassword>
                            }
                            <p className="text-muted olatext" style={{ fontSize: '10px' }}><strong>Created_at:</strong> '.{users?.created_at?.slice(0, 10)}'</p>
                        </ChangePassword>

                    </ProfileText>
                </ProfileContent>
            </ProfileBody >
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        sidebar: state?.data?.sidebar,
        user_data: state?.data?.user_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data)),
        setUserData: (data) => dispatch(setUserData(data)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);