import React, {  useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import { ModalCloseButton,ModalDarkBackGround, ModalStyleAllService, ModalCloseButtonAllService, ModalStyleMobileResponsive, CloseButton} from "../styles/MainLayout"
import {ModalStyleMobile , ModalCloseButtonMobile, CloseButtonMobile} from "../styles/MainLayoutResponsive"
import { AddService , EditViewService } from "../form/ServiceForm";

const MembershipModal = (props) => {
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
console.log("PROPSAT" , props)
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                <ModalPortal>
                    <ModalDarkBackGround onClick={()=> props.handleModalClose()}/>
                    <ModalStyleAllService>
                    <CloseButton>
                            <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                            </CloseButton>

                    {props?.type === "add"? 
                    <AddService getNewServices={props.getNewServices} handleModalClose={props.handleModalClose}/>
                    :
                    props?.type === "edit" ?
                    <EditViewService
                    getNewServices={props.getNewServices} handleModalClose={props.handleModalClose}
                      data={props.dataToModal} type={props?.type} 
                     />
                    :
                    ""
                    }

                    </ModalStyleAllService>
                </ModalPortal>
            </>
        )}
        //   ----MOBILE VIEW----- //
        else{
            return(
                <>
                <ModalDarkBackGround onClick={()=> props.handleModalClose()}/>
                <ModalStyleMobileResponsive>
                    <CloseButtonMobile>

                <ModalCloseButtonMobile type={"button"} onClick={()=> props?.handleModalClose()}>X</ModalCloseButtonMobile>
                    </CloseButtonMobile>
                {props?.type === "add"? 
                    <AddService getNewServices={props.getNewServices} handleModalClose={props.handleModalClose}/>
                    :
                    props?.type === "edit"?
                    <EditViewService   getNewServices={props.getNewServices} handleModalClose={props.handleModalClose}
                    data={props.dataToModal} type={props?.type} />
                    :
                    ""
                    }

                    </ModalStyleMobileResponsive>
                </>
           
            )
        }
    }
    else
        return null
}

export default MembershipModal
