import React, { } from 'react'
import { useState } from 'react';
import { ModalDarkBackGround } from "../../styles/MainLayout"
import ModalPortal from "../../../portal/ModalPortal";
import { FormTitle, DeleteModal, DeleteModalButton,ModalStyleDelete, ModalDeleteButton,ModalCancelButton, FormTitleResponsive } from "../../styles/MainLayout";
import axios from '../../../axios';
import { toast } from 'react-toastify';
import "../../../components/form/LanguageForm/language.scss"

const MembershipDeleteModal = (props) => {
    //AXIOS (Delete)

    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });

    const handleDelete = (e) => {
        e.preventDefault()
      axios
        .delete(`/api/admin/subscriptions/${props?.dataToModal?.id}`)
        .then((response) => {
          console.log("olaLabels", response.data);
          toast.success("Label deleted sucessfully.")
          props?.handleModalClose();
          props?.getNewMembership();
        }).catch(err => {
          toast.error("Something went wrong")
          console.log(err)
        })
  };
    //AXIOS
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal >
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyleDelete>
                            <DeleteModal className='mt-5'>
                                <i class="fa-solid fa-triangle-exclamation fa-fade display-1" style={{ color: "#c22929" }}></i>
                                <FormTitle className="d-flex justify-content-center mt-3">Are you sure you want to delete "<strong> {props?.dataToModal?.name}</strong>"? </FormTitle>
                                <p className='a'>Do you really want to delete this membership? This <br />process cannot be undone.</p>
                            </DeleteModal>
                                <DeleteModalButton className=' mt-5'>
                                    <ModalCancelButton onClick={() => props.handleModalClose()}>Cancel</ModalCancelButton>
                                    <ModalDeleteButton onClick={(e) => handleDelete(e)} >Delete</ModalDeleteButton>
                                </DeleteModalButton>
                        </ModalStyleDelete>
                    </ModalPortal>
                </>
            )
        }
        else {
            return (
                <>
                    <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                    <ModalStyleDelete>
                            <DeleteModal className='mt-5'>
                                <i class="fa-solid fa-triangle-exclamation fa-fade display-1" style={{ color: "#c22929" }}></i>
                                <FormTitleResponsive className="d-flex justify-content-center mt-3">Are you sure you want to delete  "{props?.dataToModal?.name}"? </FormTitleResponsive>
                                <p className='a'>Do you really want to delete this membership <br/>-ship? This process cannot be undone.</p>
                            </DeleteModal>
                                <DeleteModalButton className=' mt-5'>
                                    <ModalCancelButton onClick={() => props.handleModalClose()}>Cancel</ModalCancelButton>
                                    <ModalDeleteButton onClick={(e) => handleDelete(e)} >Delete</ModalDeleteButton>
                                </DeleteModalButton>
                        </ModalStyleDelete>
                </>
            )
        }
    }
    else
        return null
}

export default MembershipDeleteModal
