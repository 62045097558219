import React from "react";
import { AddButton, PaginationStyle, TableStyle } from "../../styles/MainLayout";
import { CardStyle } from "../../styles/MainLayoutResponsive"
import axios from "../../../axios";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { setSideBar } from "../../../redux/Functions/actions";
import { Link, useParams } from "react-router-dom";
import SearchInputHeader from "../SearchInputHeader";
import DeleteModals from "../../Modal/salonModals/DeleteModal";
import EditSalonModal from "../../Modal/salonModals/EditSalonModal";
import { toast } from "react-toastify";



const SalonTable = (props) => {
    const { id } = useParams();
    const [dataToModal, setDataToModal] = useState(null);


    //AXIOS API's
    const [allSalons, setAllSalons] = useState([])
    const getData = () => {
        axios.get('/api/admin/salon/all')
            .then(data => {
                console.log('allusers get req', data.data);
                setAllSalons(data.data)
            }).catch(err => console.log(err));
    }


    ///axios
    const [Membership, setMembership] = useState([])
    const getMembership = () => {
        axios
            .get("/api/admin/subscriptions/all")
            .then((response) => {
                console.log("olaLabels", response.data);
                setMembership(response.data)

            }).catch(err => {
                console.log(err)
            })
    }
    const [owners, setOwners] = useState([])
    const getOwners = () => {
        axios.get("/api/admin/users/activeStoreAdmins").then(
            data => {
                console.log("api users", data.data)
                setOwners(data.data)
            }
        ).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {
        getOwners()
        getData()
        getMembership()
    }, [])
    //MODAL STATES
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [singleUserData, setSingleUserData] = useState({})
    const [modalType, setModalType] = useState(null);
    //MODAL STATES

    //MODAL HANDLERS
    const handleModalAdd = () => {
        setIsOpen(true);
        setModalType("add");

    };

    const handleModalEdit = (data) => {
        setIsOpen(true);
        setModalType("edit");
        setSingleUserData(data);
    };
    const handleListingStatus = async (e, salon) => {
        const newValue = e.target.checked;
        try {
            const response = await axios.put('/api/admin/salon/hide', {
                salon_id: salon.id,
                should_hide: newValue,
            });
            toast.success(response.data.message);
        } catch (error) {
            console.error(error);
            toast.error('Ndodhi një gabim gjatë përditësimit të salonit');
        }
    };

    const handleModalClose = () => {
        setIsOpen(false);
        setIsOpenDelete(false);
    }

    const handleModalDelete = (data) => {
        setIsOpenDelete(true);
        setModalType("delete");
        setDataToModal(data)
    }


    //MODAL HANDLERS

    const [viewportSize, setViewportSize] = useState(window.innerWidth); //DESKTOP VIEW & MOBILE VIEW

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(false);
    const startIndex = currentPage * 7;
    const endIndex = startIndex + 7;
    const numPages = Math?.ceil(allSalons?.length / 7)

    const data = allSalons?.slice(startIndex, endIndex);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };


    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    if (viewportSize > 800)
        return (
            <>
                <SearchInputHeader />
                {isOpen === true ? (
                    <EditSalonModal
                        getNewSalon={getData}
                        isOpen={isOpen}
                        type={modalType}
                        dataToModal={singleUserData}
                        handleModalClose={handleModalClose}
                    />
                ) : (
                    ""
                )}
                {isOpenDelete === true ? (
                    <DeleteModals
                        getNewSalon={() => getData()}
                        isOpen={isOpenDelete}
                        type={modalType}
                        dataToModal={dataToModal}
                        handleModalClose={handleModalClose}
                    />
                ) : (
                    ""
                )}
                <TableStyle collapse={props?.sidebar}>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                {/* <th scope="col-1">ID</th> */}
                                <th scope="col-5">Salon Al</th>
                                <th scope="col-3">Owner</th>
                                <th scope="col-5">Subscription</th>
                                <th scope="col-6">Created_at</th>
                                <th scope="col-3">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((el) => {
                                //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
                                const date = new Date(el?.created_at)
                                const formatDate = date?.toLocaleDateString()

                                return (
                                    <>
                                        <tr>
                                            {/* <th scope="row" className="col-1">
                                                {el?.id}
                                            </th> */}
                                            <td className="col-4">{el?.name_al}</td>
                                            <td className="col-3">
                                                {owners?.map(elm => elm?.id).includes(el?.owner_id) ?

                                                    <>
                                                        <Link className="text-dark" to={`/profile/${el.owner_id}`}>
                                                            {owners?.find(elm => elm?.id == el?.owner_id)?.name}{" "}
                                                            {owners?.find(elm => elm?.id == el?.owner_id)?.surname}
                                                        </Link>
                                                    </>
                                                    :
                                                    <p className="text-danger">no owner</p>
                                                }
                                            </td>
                                            <td className="col-3">
                                                {Membership?.find((elm) => elm.id == el?.subscription_id)?.name}
                                            </td>
                                            <td className="col-4">{formatDate}</td>
                                            <td className="col">
                                                <div className="d-flex justify-content-center gap-3">
                                                    <div class="toggle-container">
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggle-switch" defaultChecked={el.should_hide

                                                            } onChange={(event) => handleListingStatus(event, el)} />
                                                            <span class="slider"></span>
                                                        </label>
                                                    </div>
                                                    <Link className="text-dark" to={`/salonAddresses/${el.id}`}>
                                                        <i class="fa-regular fa-eye mt-1  iconsForCursor" />
                                                    </Link>
                                                    <i class="fa-regular fa-pen-to-square mt-1 iconsForCursor" onClick={() => handleModalEdit(el)} />
                                                    <i class="fa-regular fa-trash-can  mt-1 iconsForCursor" onClick={() => handleModalDelete(el)} />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </TableStyle>
                <PaginationStyle collapse={props?.sidebar}>
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={numPages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination justify-content-center"}
                        activeClassName={"active"}
                        currentPage={allSalons?.length > 7 ? true : false}
                    />
                </PaginationStyle>
                <Link to={'/addsalon'}>
                    <AddButton >
                        <i type={"button"} className="fa-solid fa-circle-plus fa-xl iconsForCursor" style={{ color: "#FF5758" }}></i>
                    </AddButton>
                </Link>
            </>
        )
    else {
        return (
            <>
                <SearchInputHeader />
                {isOpen === true ? (
                    <EditSalonModal
                        getNewSalon={getData}
                        isOpen={isOpen}
                        type={modalType}
                        dataToModal={singleUserData}
                        handleModalClose={handleModalClose}
                    />
                ) : (
                    ""
                )}
                {isOpenDelete === true ? (
                    <DeleteModals
                        getNewSalon={() => getData()}
                        isOpen={isOpenDelete}
                        type={modalType}
                        dataToModal={dataToModal}
                        handleModalClose={handleModalClose}
                    />
                ) : (
                    ""
                )}
                <div className="container">
                    {data?.map((el) => {
                        //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
                        const date = new Date(el?.created_at)
                        const formatDate = date?.toLocaleDateString()
                        return (
                            <>
                                <CardStyle>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-8">
                                                <ul className="list-group gap-3 pt-2 pb-2 ps-3">
                                                    <li class="list-group"><span><strong>Salon AL</strong>: {el.name_al}</span></li>
                                                    <li class="list-group"><span><strong>Membership</strong> :
                                                        {Membership?.find((elm) => elm.id == el?.subscription_id)?.name}
                                                    </span></li>
                                                    <li class="list-group"><span><strong>Created_at</strong> : {formatDate}</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-3 pt-2">
                                                <div className="d-flex gap-2">
                                                    <Link className="text-dark" to={`/salonAddresses/${el.id}`}>
                                                        <i class="fa-regular fa-eye mt-1  iconsForCursor" />
                                                    </Link>
                                                    <i class="fa-regular fa-pen-to-square mt-1 iconsForCursor" onClick={() => handleModalEdit(el)} />
                                                    <i class="fa-regular fa-trash-can  mt-1 iconsForCursor" onClick={() => handleModalDelete(el)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </CardStyle>
                            </>
                        )
                    })}
                    <Link to={'/addsalon'}>
                        <AddButton>
                            <i type={"button"} className="fa-solid fa-circle-plus fa-xl iconsForCursor" style={{ color: "#FF5758" }}></i>
                        </AddButton>
                    </Link>
                </div>
            </>
        )

    }
}
// export default SalonTable
const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        sidebar: state?.data?.sidebar,
        dataFilter: state?.data?.dataFilter
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SalonTable);