import React from "react";
import {
    UsersForm, FormControl, Label, Input, ModalEditButtonServices, DivForIcon,
    FormTitle, NameSurname, NameFirst, Surname, ImageForServices,
    LabelProfile, DivForLabelAndInput, InputProfileForm, ModalAddButtonUsers, ModalEditButtonUsers
} from "../styles/MainLayout";
import axios from "../../axios"
import { toast } from "react-toastify";
import { useState } from "react";
import "./Services/services.scss"
export const AddService = (props) => {
    const [image, setImage] = useState()
    const [serviceName, setServiceName] = useState()
    const [serviceNameEn, setServiceNameEn] = useState()


    //axios
    const addService = (e) => {
        e.preventDefault()
        console.log("object", e.target)
        const formData = new FormData();
        formData.append('name_al', serviceName);
        formData.append('name_en', serviceNameEn);
        formData.append('img_url', image);

        axios.post("/api/admin/services", formData).then(
            data => {
                console.log(data.data)
                toast.success("Service added successfully")
                props.getNewServices()
                props.handleModalClose()
            }
        ).catch(
            err => {
                console.log(err)
                toast.error("Something went wrong")
            }
        )
    }
    return (
        <>
            <FormTitle className="  d-flex justify-content-center mt-3 ">Add Service</FormTitle>
            <hr />
            <UsersForm onSubmit={addService}>
                <FormControl>
                    <NameSurname>
                        <NameFirst>
                            <DivForLabelAndInput className="forDisable ">
                                <LabelProfile >Emri i Sherbimit Shqip</LabelProfile>
                                <InputProfileForm required onChange={(e) => setServiceName(e.target.value)} placeholder="" type="text" name="name_al" />
                            </DivForLabelAndInput>
                            <DivForLabelAndInput className="forDisable ">
                                <LabelProfile >Service Name English</LabelProfile>
                                <InputProfileForm required onChange={(e) => setServiceNameEn(e.target.value)} placeholder="" type="text" name="name_en" />
                            </DivForLabelAndInput>
                        </NameFirst>
                    </NameSurname>
                    <br />
                    {/* <DivForIcon className="forDisable"> */}
                    <LabelProfile >Service image</LabelProfile>
                    <br />
                    <ImageForServices>
                    </ImageForServices>
                    {/* </DivForIcon> */}
                    <InputProfileForm required placeholder="" type="file" onChange={(e) => setImage(e.target.files[0])} />
                    <br />
                    <div className="d-flex justify-content-end mt-5 mb-2">
                    <ModalEditButtonServices type={"submit"}>Add</ModalEditButtonServices>
                    </div>
                </FormControl>
            </UsersForm>
        </>
    );
};

export const EditViewService = (props) => {
    console.log('drill', props)
    const [image, setImage] = useState()
    // const editBody = {
    //     id: props?.dataToModal?.id,
    //     name_al: name_al,
    //     name_en: name_en,
    // }

    //AXIOS-- Edit new User
    const [editServiceName, setEditServiceName] = useState(props?.data?.name_al || undefined);
    const [editServiceNameEn, setEditServiceNameEn] = useState(props?.data?.name_en || undefined);

    const handleEdit = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('id', props?.data?.id);
        formData.append('name_al', editServiceName);
        formData.append('name_en', editServiceNameEn);
        formData.append('img_url', image);
        axios
            .put(`/api/admin/services`, formData)
            .then((response) => {
                props?.handleModalClose();
                props?.getNewServices();
                toast.success("Label edited sucessfully.")
            }).catch(err => {
                toast.error(err)
                console.log(err)
            })
    };

    return (
        <>
            <FormTitle className="d-flex justify-content-center mt-3 edit_service_modal_layout">Edit Service </FormTitle>
            <hr />
            <UsersForm type={props?.type} onSubmit={handleEdit} >
                <FormControl>
                    <NameSurname>
                        <NameFirst>
                            <DivForLabelAndInput className="forDisable">
                                <LabelProfile >Emri i Sherbimit Shqip</LabelProfile>
                                <InputProfileForm defaultValue={props?.data?.name_al} onChange={(e) => setEditServiceName(e.target.value)} id="name_al" name="name_al" type="text" />
                            </DivForLabelAndInput>
                            <DivForLabelAndInput className="forDisable">
                                <LabelProfile >Service Name English</LabelProfile>
                                <InputProfileForm defaultValue={props?.data?.name_en} onChange={(e) => setEditServiceNameEn(e.target.value)} id="name_en" name="name_en" type="text" />
                            </DivForLabelAndInput>
                        </NameFirst>
                        {/* <Surname>
                        <DivForLabelAndInput className="forDisable">
                            <LabelProfile >Service Name</LabelProfile>
                            <InputProfileForm defaultValue={props?.data?.name_en} id="name_en" name="name_en" type="text" />
                        </DivForLabelAndInput>
                        </Surname> */}
                    </NameSurname>
                    <br />
                    <DivForIcon className="forDisable">
                        <LabelProfile >Service image</LabelProfile>
                        <ImageForServices>
                            <img src={`${process.env.REACT_APP_API}/${props?.data?.img_url}`} className="menu-icons-collapsed service_image" style={{ width: "100px" }} />
                        </ImageForServices>
                    </DivForIcon>
                    <InputProfileForm placeholder="" type="file" onChange={(e) => setImage(e.target.files[0])} />
                    <br />
                    <div className="d-flex justify-content-end mt-5 mb-2">
                    <ModalEditButtonServices type={"submit"}>Edit</ModalEditButtonServices>
                    </div>
                </FormControl>
            </UsersForm>
        </>
    )
}


