import React from "react";
import { AddButton, PaginationStyle, TableStyle } from "../../../components/styles/MainLayout"
import { CardStyle } from "../../../components/styles/MainLayoutResponsive"
import axios from "../../../axios";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { setSideBar } from "../../../redux/Functions/actions";
import { Link, useParams } from "react-router-dom";
import SearchInputHeader from "../../../components/form/SearchInputHeader";
import DeleteModals from "../../../components/Modal/WorkingHoursModal/workingHours"
import AddLocation from "../../../components/Modal/salonModals/addLocation";
import "./salon.scss"
import SalonAddressModal from "../../../components/Modal/SalonAddressModal"


const SalonLocations = (props) => {
    const { id } = useParams();
    const [dataToModal, setDataToModal] = useState(null);
    const [isOpenDelete, setIsOpenDelete] = useState(false);


    console.log('qikjo id ', id)
    //AXIOS API's
    const [allSalons, setAllSalons] = useState([])
    const getData = () => {
        axios.get(`/api/admin/salon_address/all_addresses/${id}`).then(data => {
            console.log('salon_address get req', data.data);
            setAllSalons(data.data)
        }).catch(err => console.log(err));
    }
    console.log("object", allSalons)
    useEffect(() => {
        getData()
    }, [])

    //MODAL STATES
    const [isOpen, setIsOpen] = useState(false);
    const [singleUserData, setSingleUserData] = useState({})
    const [modalType, setModalType] = useState(null);
    //MODAL STATES

    //MODAL HANDLERS
    const handleModalAdd = () => {
        setIsOpen(true);
        setModalType("add");

    };

    const handleModalEdit = (data) => {
        setIsOpen(true);
        setModalType("edit");
        setSingleUserData(data);
    };
    const handleModalClose = () => {
        setIsOpen(false);
        setIsOpenDelete(false);

    }

    const handleModalDelete = (data) => {
        setIsOpenDelete(true);
        setModalType("delete");
        setDataToModal(data)
    }


    const handleAddLocation = (data) => {
        setIsOpen(true);
        setModalType("addLocation");
        setDataToModal(data)

    }
    //MODAL HANDLERS

    const [viewportSize, setViewportSize] = useState(window.innerWidth); //DESKTOP VIEW & MOBILE VIEW

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(false);
    const startIndex = currentPage * 9;
    const endIndex = startIndex + 9;
    const numPages = Math.ceil(allSalons?.length / 9)
    const data = allSalons?.slice(startIndex, endIndex);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };
    console.log('hejjjjjjjjjjjjjjjjjjjj')

    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    if (viewportSize > 800)
        return (
            <>
                <SearchInputHeader />
                {isOpen === true ? (
                    <DeleteModals
                        getNewLabels={() => getData()}
                        isOpen={isOpen}
                        type={modalType}
                        dataToModal={dataToModal}
                        handleModalClose={handleModalClose}
                    />
                ) : ("")}
                {isOpen == true
                    &&
                    modalType == "addLocation"
                    &&
                    <AddLocation
                        getData={getData}
                        handleModalClose={handleModalClose}
                        isOpen={isOpen}
                        type={modalType}
                    />
                }
                {isOpenDelete === true ? (
                    <SalonAddressModal
                        getNewAddress={() => getData()}
                        isOpen={isOpenDelete}
                        type={modalType}
                        dataToModal={dataToModal}
                        handleModalClose={handleModalClose}
                    />
                ) : ("")}

                {allSalons?.length === 0 ?
                <>
                    <div className="noSalon">
                        <h3>There is no Salon Address to show</h3>
                        <hr/>
                    </div>

                </>
                    :
                    <TableStyle collapse={props?.sidebar}>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    {/* <th scope="col-1">ID</th> */}
                                    <th scope="col-5">City</th>
                                    <th scope="col-5">Street</th>
                                    <th scope="col-6">
                                        {/* Created_by */}
                                    </th>
                                    <th scope="col-6">Created_at</th>
                                    <th scope="col-3">Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data?.map((el) => {
                                    console.log("eriolaaaaaaaaaLightbox", data)
                                    //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
                                    const date = new Date(el?.created_at)
                                    const formatDate = date.toLocaleDateString()

                                    return (
                                        <>
                                            <tr>
                                                {/* <th scope="row" className="col-1">
                                                    {el?.id}
                                                </th> */}
                                                <td className="col-5">{el?.name_al}</td>
                                                <td className="col-5">{el?.street_address_al}</td>
                                                <td className="col-6">{el?.created_by}</td>
                                                <td className="col-6">{formatDate}</td>


                                                <td className="col">
                                                    <div className="d-flex justify-content-center gap-3 iconsForCursor">
                                                        <Link className="text-dark" to={`/editsalon/${el?.id}`}>
                                                            <i class="fa-regular fa-pen-to-square iconsForCursor" />

                                                        </Link>
                                                        <i class="fa-regular fa-trash-can ms-1 mt-1 iconsForCursor" onClick={() => handleModalDelete(el)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}



                            </tbody>
                        </table>
                    </TableStyle>
                }

            {allSalons?.length === 0 ?
" "
:
                <PaginationStyle collapse={props?.sidebar}>
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={numPages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination justify-content-center"}
                        activeClassName={"active"}
                        currentPage={allSalons?.length > 7 ? true : false}
                    />

                </PaginationStyle>
                }
                {/* <Link to={'/add_location'}> */}
                <AddButton onClick={() => handleAddLocation(id)} >
                    <i type={"button"} className="fa-solid fa-circle-plus fa-xl " style={{ color: "#FF5758" }}></i>
                </AddButton>
                {/* </Link> */}
            </>
        )
    else {
        return (
            <>
                <SearchInputHeader />
                {isOpen === true ? (
                    <DeleteModals
                        getNewLabels={() => getData()}
                        isOpen={isOpen}
                        type={modalType}
                        dataToModal={dataToModal}
                        handleModalClose={handleModalClose}
                    />
                ) : ("")}
                {isOpen == true
                    &&
                    modalType == "addLocation"
                    &&
                    <AddLocation
                        getData={getData}
                        handleModalClose={handleModalClose}
                        isOpen={isOpen}
                        type={modalType}
                    />
                }
                {isOpenDelete === true ? (
                    <SalonAddressModal
                        getNewAddress={() => getData()}
                        isOpen={isOpenDelete}
                        type={modalType}
                        dataToModal={dataToModal}
                        handleModalClose={handleModalClose}
                    />
                ) : ("")}

{allSalons?.length === 0 ?
                <>
                    <div className="noSalonResponsive">
                        <h6>There is no Salon Address to show</h6>
                        <hr/>
                    </div>

                </>
                    :
                    <div className="container">

                        {data?.map((el) => {
                            //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
                            const date = new Date(el?.created_at)
                            const formatDate = date.toLocaleDateString()
                            return (
                                <>
                                    <CardStyle>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-8">
                                                    <ul className="list-group gap-3 pt-2 pb-2 ps-3">
                                                        {/* <li class="list-group"><span><strong>ID</strong>: {el?.id}</span></li> */}
                                                        <li class="list-group"><span><strong>Salon AL</strong>: {el.name_al}</span></li>
                                                        <li class="list-group"><span><strong>Salon EN</strong> : {el.name_en}</span></li>
                                                        <li class="list-group"><span><strong>Created_at</strong> : {formatDate}</span></li>

                                                    </ul>
                                                </div>
                                                <div className="col-3 pt-2">
                                                    <div className="d-flex gap-3">
                                                    <Link className="text-dark" to={`/editsalon/${el?.id}`}>
                                                            <i class="fa-regular fa-pen-to-square iconsForCursor" />
                                                        </Link>
                                                        <i class="fa-regular fa-trash-can ms-1 mt-1" onClick={() => handleModalDelete(el)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </CardStyle>
                                </>
                            );
                        })}

                        {/* <Link to={'/addsalon'}> */}
                        <AddButton onClick={() => handleAddLocation(id)}>
                            <i type={"button"} className="fa-solid fa-circle-plus fa-xl " style={{ color: "#FF5758" }}></i>
                        </AddButton>
                        {/* </Link> */}
                    </div>
                }

            </>
        )
    }
}
// export default SalonLocations
const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        sidebar: state?.data?.sidebar,
        dataFilter: state?.data?.dataFilter
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SalonLocations);