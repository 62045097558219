import axios from "axios";

const defaultInstance = axios.create({
  baseURL: process.env.REACT_APP_API,

});

defaultInstance.interceptors.request.use(
  config => {
    let token = window.localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
export default defaultInstance;