import React, { useState } from "react";
import {
    UsersForm, NameFirst, FormControl, Surname,
    DivForLabelAndInputName, LabelProfile, FormTitle,
    ModalEditButton, MapStyle, NameSurname, InputProfileForm, DivForLabelAndInputUser, ModalEditButtonLocation
} from "../../styles/MainLayout";
import { useParams } from "react-router-dom";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import axios from "../../../axios"
import { toast } from "react-toastify";

const AddLocation = (props) => {
    const [markerPosition, setMarkerPosition] = useState(null);
    const { id } = useParams();


    const handleMapClick = (mapProps, map, clickEvent) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();
        const newPosition = { lat, lng };
        setMarkerPosition(newPosition);
    };

    const LocationAdd = (e) => {
        e.preventDefault()
        if (markerPosition === null) {
            toast.warning("Please select the location on google maps")
        }
        else {

            let body = {
                salon_id: Number(id),
                name_al: e.target.name_al.value,
                name_en:  e.target.name_al.value,
                street_address_al: e.target.street_address_al.value,
                street_address_en: e.target.street_address_al.value,
                latitude: markerPosition?.lat,
                longitude: markerPosition?.lng,
                desc_al: e.target.desc_al.value,
                desc_en: e.target.desc_al.value,
                created_by: 1
            }
            axios.post("/api/admin/salon_address/", body)
                .then(data => {
                    console.log("data salon address", data.data)
                    props?.getData()
                    props?.handleClose();
                    toast.success(data?.data?.message)
                }).catch(err => {
                    toast.error(err)
                    console.log(err)
                })
        }
    }

    return (
        <>
            <FormTitle className="d-flex justify-content-center mt-3">Add Location</FormTitle>
            <hr />
            <UsersForm onSubmit={LocationAdd} >
                <FormControl >
                    <NameSurname>
                        <NameFirst className="col">
                            <DivForLabelAndInputUser className="forDisable">
                                <LabelProfile >Qyteti</LabelProfile>
                                <InputProfileForm required id="name_al" name="name_al" placeholder="...." type="text" />
                            </DivForLabelAndInputUser>
                        </NameFirst>

                        {/* <Surname className="col">
                        <DivForLabelAndInputUser className="forDisable">
                            <LabelProfile>City</LabelProfile>
                            <InputProfileForm required id="name_en" name="name_en" placeholder="...." type="text" />
                        </DivForLabelAndInputUser>
                        </Surname> */}
                    </NameSurname>
                    <NameSurname>
                        <NameFirst className="col">

                            <DivForLabelAndInputUser className="forDisable">
                                <LabelProfile> Emri Rruges </LabelProfile>
                                <InputProfileForm required id="street_address_al" name="street_address_al" placeholder="...." type="text" />
                            </DivForLabelAndInputUser>
                        </NameFirst>
                        {/* <Surname>

                        <DivForLabelAndInputUser className="forDisable">
                            <LabelProfile> Street Name </LabelProfile>
                            <InputProfileForm required id="street_address_en" name="street_address_en" placeholder="...." type="text" />
                        </DivForLabelAndInputUser>
                        </Surname> */}
                    </NameSurname>
                    <NameSurname>
                        <NameFirst className="col">

                            <DivForLabelAndInputUser className="forDisable">
                                <LabelProfile> Pershkrimi </LabelProfile>
                                <textarea className="form-control" cols="30" rows="2" required id="desc_al" name="desc_al" placeholder="...." type="text" />
                            </DivForLabelAndInputUser>
                        </NameFirst>
                        {/* <Surname>

                        <DivForLabelAndInputUser className="forDisable">
                            <LabelProfile> Description </LabelProfile>
                            <textarea className="form-control" cols="30" rows="2" required id="desc_en" name="desc_en" placeholder="...." type="text" />
                        </DivForLabelAndInputUser>
                        </Surname> */}
                    </NameSurname>
                    <MapStyle>
                        <div className="col-9">

                            <Map className="mapLocation"
                                containerStyle={{ width: '276px', height: "200px", position: "relative" }}
                                google={props.google}
                                zoom={14}
                                initialCenter={{
                                    lat: 42.66299247342869,
                                    lng: 21.162134198227506
                                }}
                                //   ref={mapRef}
                                streetViewControl={false}
                                onClick={handleMapClick}
                            >
                                {markerPosition && <Marker position={markerPosition} />}

                                {/* <div style={{width:'300px' , height:'10vh'}}>
                <PlacesAutocomplete
                onChange={(address) => handlePlaceSelect(address)}
                />
                      Z </div> */}
                            </Map>
                        </div>
                        <div className="col-3">
                            <ModalEditButtonLocation type="submit">Add</ModalEditButtonLocation>
                        </div>
                    </MapStyle>
                </FormControl>
            </UsersForm>
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAx2mpUulK8e5rn9PB09xmSDDccbCoVMps'
})(AddLocation);
