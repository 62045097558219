import React, { useState } from "react";
import {
  FormLogin,
  InputEmail,
  InputPass,
  Buttons,
  Paragraf,
  Loginbutton,
  Labels,
  RegisterbuttonAtLogin,
  EyePass
} from "../../styles/MainLayout";
import axios from "../../../axios";
import { connect } from "react-redux";
import { setLoggedIn, setUserData, setUserToken, setExpiredToken } from "../../../redux/Functions/actions";
import Aos from "aos";
import { useNavigate } from "react-router";
import sign from "jwt-encode"
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
const LoginForm = (props) => {
  console.log('login dajko props', props)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const secretKey = process.env.REACT_APP_SECRET_KEY

  console.log('env secret key ', process.env.REACT_APP_SECRET_KEY)
  let passEnc = sign(password, secretKey)

  const body = { email: email, password: passEnc };
  const navigate = useNavigate();

  const navigateToRegister = () => {
    navigate('/register');
  };

  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post("/api/auth/login", body)
      .then((response) => {
        console.log(response)
        if (response.status === 200) {
          window.localStorage.setItem('token', response?.data?.token);
          const secretKey = process.env.REACT_APP_SECRET_KEY
          const decodeToken = jwtDecode(response.data.token, String(secretKey))
          if (decodeToken?.role === Number(1)) {
            window.localStorage.setItem("token", response.data.token)
            toast.success("You are logged in");
            props?.setUserToken(response.data.token)
            props?.setExpiredToken(decodeToken?.logout_timer);
            props?.setUserData(
              {
                role: decodeToken?.role,
                user_id: decodeToken?.user_id,
                salon_id: decodeToken?.salon_id,
                auth: decodeToken?.auth,
                name: decodeToken?.name,
                surname: decodeToken?.surname,
                gender: decodeToken?.gender,
                language: decodeToken.language,
                phoneNumber: decodeToken.phoneNumber
              }
            )
            props?.setLoggedIn(true)
            navigate("/")

          } else {
            toast.error("You do not have access to this site!")
          }
          console.log("decodeToken", decodeToken)

        }
      })
      .catch((error) => {
        toast.error("Authentication failed!");
        console.log('error:', error);
      });
  };

  Aos.init();


  const [passwordType, setPasswordType] = useState("password");
  const togglePassword =()=>{
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }

  return (
    <>
      {/* <LoginLayout className="container">
        <Loginform data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="900">
          <Text>
            <Heading>Welcome back!</Heading>
            <Paragraph>Please enter your details</Paragraph>
          </Text> */}
   <FormLogin onSubmit={(e) => handleLogin(e)}>
        <Labels>Email</Labels>
        <InputEmail
          required
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="email"
          placeholder="Enter your email"
        ></InputEmail>
        <Labels>Password</Labels>
        <EyePass>
        <InputPass
          required
          type={passwordType}
          placeholder="********"
          // value={passwordInput} 
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          >

        </InputPass>
  { passwordType==="password"? <i class="fa fa-eye-slash passEye " onClick={togglePassword}></i> :<i class="fa fa-eye passEye " onClick={togglePassword}></i> }
          </EyePass>
        <Buttons>
          <Paragraf>Forgot password?</Paragraf>
          <Loginbutton type="submit">Login</Loginbutton>
        </Buttons>
        <RegisterbuttonAtLogin onClick={navigateToRegister} >Register now</RegisterbuttonAtLogin>


      </FormLogin>
      {/* </Loginform>
        <FormLayout>
          <FormRegister>
            <Image>
              <img src={Photo} alt="" />
            </Image>
            <Heading3>
              Don't have an <br /> account?
            </Heading3>
            <ButtonRegister>
              <ButtonR onClick={navigateToRegister}>Register now</ButtonR>
            </ButtonRegister>
          </FormRegister>
        </FormLayout>
      </LoginLayout> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    logged_in: state.data.logged_in,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    setUserData: (data) => dispatch(setUserData(data)),
    setUserToken: (data) => dispatch(setUserToken(data)),
    setExpiredToken: (data) => dispatch(setExpiredToken(data)),


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
