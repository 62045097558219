import React, { useEffect, useState } from "react";
import { UsersForm, FormControl, Label, Input, ModalAddButton, FormTitle, ModalAddButtonLocation, MapStyle,ModalStyle, ModalDarkBackGround, DivForLabelAndInputLang, ModalCloseButton } from "../../styles/MainLayout";
import { useParams } from "react-router-dom";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import axios from "../../../axios"
import { toast } from "react-toastify";
import { connect } from "react-redux";
import ModalPortal from "../../../portal/ModalPortal";


const AddLocationFormSalon = (props) => {
    const [staff, setStaff] = useState([])
    const [users, setUsers] = useState([])
    const [deletedUsers, setDeletedUsers] = useState([])
    const [workingStaff, setWorkingStaff] = useState();

    const { id } = useParams();
    console.log('ajshe, ', props)
    const getStaff = () => {
        axios.get(`/api/admin/users/salon_address_workers/${id}`).then(
            response => {
                console.log("salon address single", response)
                setStaff(response.data.filter(el => el?.salon_address_id == id && el?.role_id == 4))
            }
        ).catch(err => {
            console.log(err)
        })
    }
    const getServiceWorkers = () => {
        axios.get(`/api/admin/user_services/singleWorker/${props?.data?.id}/${id}`)
            .then(res => {
                setWorkingStaff(res.data)
            }
            ).catch(err => {
                console.log(err)
            })

    }
    console.log("singleWorker", workingStaff)

    useEffect(() => {
        getServiceWorkers();
        getStaff();
    }, [])


    function setNewWorker(event, newWorker) {
        if (event.target.checked === true) {
            setUsers(prevUsers => [...prevUsers, { user_id: newWorker?.id, sub_service_id: props?.data?.id }]);
        } else if (event.target.checked === false) {
            setUsers(prevUsers => {
                const updatedUsers = prevUsers.filter(user => user.user_id !== newWorker?.id);
                const isUserInDeletedUsers = deletedUsers.some(user => user.user_id === newWorker?.id);

                if (!isUserInDeletedUsers) {
                    const filteredDeleteUser = workingStaff?.filter(user => user.user_id === newWorker?.id);
                    setDeletedUsers(prevDeleteUser => [...prevDeleteUser, ...filteredDeleteUser]);
                }
                return updatedUsers;
            });
        }
    }



    console.log('userssss true', users, workingStaff, deletedUsers)

    console.log('deletedUsers', deletedUsers)


    const handelUserServices = (e) => {
        e.preventDefault();
        if (props.type === "add") {
            if (users.length === 0) {
                toast.warning("please select the staff to assign the service");
            } else if (props.data[3] === undefined) {
                toast.warning("select the time value");
            } else {
                const duration = Math.floor(props.data[3] / 60);
                const remainingDuration = props.data[3] % 60;
                const formatDuration = String(duration).padStart(2, "0");
                const formattedMinutes = String(remainingDuration).padStart(2, "0");
                let time = `${formatDuration}:${formattedMinutes}:00`;
                if (time === "NaN:NaN:00" || time === "00:00:00") {
                    toast.error("Ju lutem shenoni kohezgjatjen e shërbimit!");
                } else {
                    let body = {
                        salon_address_id: id,
                        service_id: props.data[4],  //service id
                        sub_service_id: props.data[0], //sub service id 
                        sub_sub_service_id: props.data[1], // sub sub service id
                        salon_id: props.data[5], //salon id
                        name_al: props.data[2], //service name al
                        name_en: props.data[2], //service name al
                        is_fav: props.data[6],
                        duration: time,
                    };

                    console.log("ADD SERVICE", body);
                    axios.post("/api/admin/salon_services", body).then(async (data) => {

                        console.log("prev", users)
                        const updatedUsers = users.map((user) => {
                            return { ...user, sub_service_id: data?.data?.sub_sub_service_id };
                        });
                        setUsers(updatedUsers)
                        console.log("users", updatedUsers)
                        const userData = {
                            new_users: updatedUsers,
                            delete_user: deletedUsers,
                        };
                        axios.post("/api/admin/user_services", userData).then((res) => {
                            console.log("res", res);
                            toast.success("res");
                        })
                            .catch((err) => {
                                console.log("error", err);
                                toast.error("err");
                            });

                        props.getSalonAddressService();
                        props.getSingleSalonAddress()
                        props.handleModalClose()
                        toast.success("Services have been added successfully");
                    })
                        .catch((err) => {
                            console.log(err);
                        });
                      
                }
            }
        } else if (props.type === "staf") {
            const data = {
                new_users: users,
                delete_user: deletedUsers,
            };
            axios
                .post("/api/admin/user_services", data)
                .then((res) => {
                    console.log("res", res);
                    toast.success("res");
                })
                .catch((err) => {
                    console.log("error", err);
                    toast.error("err");
                });
        }
    };

    return(
        <>
         <ModalPortal>
                <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                <ModalStyle>
                <div className='d-flex justify-content-end'>
                    <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                    </div>
    {
        props.type == "add"
            ?
            <FormTitle className="d-flex justify-content-center mt-3">Service "{props.data[2]}" belongs to :</FormTitle>
            :
            <FormTitle className="d-flex justify-content-center mt-3">Service "{props.data.name_al}" belongs to :</FormTitle>
           
    }
        < UsersForm >
        <hr/>
        <div class="container ">
            <div class=" olaaa">
                {staff?.map(el => {
                    return (
                            <div className="borderDiv">
                            <input defaultChecked={workingStaff?.find(elm => elm?.user_id == el?.id ? true : "")}
                                type="checkbox"
                                onClick={(e) => { setNewWorker(e, el) }} />   {el?.name + " " + el?.surname}
                                </div>
                    )
                })}
                
            </div>
            <div className="divForButtonAssign">

                <button className="assignServices" onClick={(e) => handelUserServices(e)}>Assign Service</button>
            </div>
        </div >
            
            </UsersForm >
            </ModalStyle>
            </ModalPortal>
            </>
            );
        };
        
        
        const mapStateToProps = (state) => {
            return {
        selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        user_data: state?.data?.user_data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //   setSideBar: (data) => dispatch(setSideBar(data)),
        //   setUserData: (data) => dispatch(setUserData(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddLocationFormSalon);
// export default AddLocation