import React, { useState } from "react";
import ModalPortal from "../../../portal/ModalPortal";
import {
    UsersForm, FormControl, FormTitle, NameSurname, ModalAddButtonSalon,
    ModalDarkBackGround, ModalStyle, ModalCloseButton, SelectOptionBox,
    InputProfileForm, DivForLabelAndInputUser, ModalCloseButtonMobile,
    LabelProfile, SelectOption, DivForLabelAndInput, NameFirst, ModalStyleMobile, ModalEditButtonServicess, CloseButton


} from "../../../components/styles/MainLayout"
import { useParams } from "react-router-dom";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { CloseButtonMobile } from "../../../components/styles/MainLayoutResponsive";

const EditGender = (props) => {
    const [Membership, setMembership] = useState([])
    const [users, setUser] = useState({});
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    const { sid } = useParams();
    console.log("propsServices", props)

    ///AXIOS
    const getMembership = () => {
        axios
            .get("/api/admin/subscriptions/all")
            .then((response) => {
                console.log("olaLabels", response.data);
                setMembership(response.data)
            }).catch(err => {
                console.log(err)
            })
    }
    //axios

    //AXIOS
    const [owner, setOwner] = useState([])
    const UserData = () => {
        axios.get('/api/admin/users/activeStoreAdmins')
            .then(data => {
                console.log('all salon owners get req', data.data);
                setOwner(data.data)
            }).catch(err => console.log(err));
    }
    const [newOwner, setNewOwner] = useState([])
    const NewUserData = () => {
        axios.get('/api/admin/users/storeAdmins')
            .then(data => {
                console.log('all salon store admins', data.data);
                setNewOwner(data.data)
            }).catch(err => console.log(err));
    }


    //AXIOS

    useEffect(() => {
        getMembership()
        UserData()
        NewUserData()
    }, [])

    //AXIOS
    const [editGenderAl, setEditGenderAl] = useState()
    const [editGenderEn, setEditGenderEn] = useState()
    const GenderEdit = (e) => {
        e.preventDefault();
        let body = {
            id: props?.dataToModal?.id,
            name_al: editGenderAl,
            name_en: editGenderEn,
        }
        console.log("body", body)
        axios.put(`/api/admin/genders/update`, body)
            .then((data) => {
                props?.handleModalClose();
                props?.getNewSalon()
                console.log(data.data)
                toast.success("Gender edited sucessfully!")
            }).catch(
                err => {
                    toast.error(err)
                    console.log(err)
                }
            )
    }
    const addGender = (e) => {
        e.preventDefault();
        let body = {
            name_al: editGenderAl,
            name_en: editGenderEn,
        }
        console.log("body", body)
        axios.post(`/api/admin/genders/`, body)
            .then((data) => {
                props?.handleModalClose();
                props?.getNewSalon()
                console.log(data.data)
                toast.success("Gender added sucessfully!")
            }).catch(
                err => {
                    toast.error(err)
                    console.log(err)
                }
            )
    }

    const date = new Date(props?.created_at)
    const formatDate = date?.toLocaleDateString()
    if (props?.isOpen) {
        console.log('propsssssssss', props)
        if (viewportSize > 800) {
            //----DESKTOP VIEW---- //
            if (props?.type == "add") {
                return (
                    <>
                        <ModalPortal>
                            <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                            <ModalStyle>
                                <CloseButton>
                                    <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                                </CloseButton>
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Add Gender</FormTitle>
                                    <hr />
                                    <UsersForm onSubmit={addGender}>
                                        <FormControl>
                                            <NameSurname>
                                                <DivForLabelAndInputUser className="forDisable">
                                                    <LabelProfile >Gjinia SHQ</LabelProfile>
                                                    <InputProfileForm onChange={(e) => setEditGenderAl(e.target.value)} id="name_al" placeholder="Gjinia AL" type="text" name="name_al" />
                                                </DivForLabelAndInputUser>
                                            </NameSurname>
                                            <NameSurname>
                                                <DivForLabelAndInputUser className="forDisable">
                                                    <LabelProfile >Gjinia ENG</LabelProfile>
                                                    <InputProfileForm onChange={(e) => setEditGenderEn(e.target.value)} id="name_en" placeholder="GjiniaENG" type="text" name="name_en" />
                                                </DivForLabelAndInputUser>
                                            </NameSurname>
                                            {/* <NameSurname> */}

                                            <ModalAddButtonSalon type={"submit"}>Add</ModalAddButtonSalon>
                                        </FormControl>
                                    </UsersForm>
                                </>
                            </ModalStyle>
                        </ModalPortal>
                    </>
                )
            }
            else {

                return (
                    <>
                        <ModalPortal>
                            <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                            <ModalStyle>
                                <CloseButton>
                                    <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                                </CloseButton>
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Edit Gender</FormTitle>
                                    <hr />
                                    <UsersForm onSubmit={GenderEdit}>
                                        <FormControl>
                                            <NameSurname>
                                                <DivForLabelAndInputUser className="forDisable">
                                                    <LabelProfile >Gjinia SHQ</LabelProfile>
                                                    <InputProfileForm defaultValue={props?.dataToModal?.name_al} onChange={(e) => setEditGenderAl(e.target.value)} id="name_al" placeholder="Gjinia AL" type="text" name="name_al" />
                                                </DivForLabelAndInputUser>
                                            </NameSurname>
                                            <NameSurname>
                                                <DivForLabelAndInputUser className="forDisable">
                                                    <LabelProfile >Gjinia ENG</LabelProfile>
                                                    <InputProfileForm defaultValue={props?.dataToModal?.name_en} onChange={(e) => setEditGenderEn(e.target.value)} id="name_en" placeholder="GjiniaENG" type="text" name="name_en" />
                                                </DivForLabelAndInputUser>
                                            </NameSurname>
                                            {/* <NameSurname> */}

                                            <ModalAddButtonSalon type={"submit"}>Edit</ModalAddButtonSalon>
                                        </FormControl>
                                    </UsersForm>
                                </>
                            </ModalStyle>
                        </ModalPortal>
                    </>
                )
            }
        }
        //   ----MOBILE VIEW----- //
        else {
            if (props?.type == "add") {
                return (
                    <>

                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyleMobile>
                            <CloseButtonMobile>

                                <ModalCloseButtonMobile type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonMobile>
                            </CloseButtonMobile>
                            <>
                                <FormTitle className="d-flex justify-content-center mt-3">Add Gender</FormTitle>
                                <hr />
                                <UsersForm onSubmit={addGender}>
                                    <FormControl>
                                        <NameSurname>
                                            <NameFirst>
                                                <DivForLabelAndInput className="forDisable">
                                                    <LabelProfile >Gender SHQ</LabelProfile>
                                                    <InputProfileForm  onChange={(e) => setEditGenderAl(e.target.value)} id="name_al" placeholder="Emri i sallonit" type="text" name="name_al" />
                                                </DivForLabelAndInput>
                                            </NameFirst>
                                            <NameFirst>
                                                <DivForLabelAndInput className="forDisable">
                                                    <LabelProfile >Gender ENG</LabelProfile>
                                                    <InputProfileForm  onChange={(e) => setEditGenderEn(e.target.value)} id="name_al" placeholder="Emri i sallonit" type="text" name="name_al" />
                                                </DivForLabelAndInput>
                                            </NameFirst>
                                        </NameSurname>
                                        <ModalEditButtonServicess type={"submit"}>Add</ModalEditButtonServicess>
                                    </FormControl>
                                </UsersForm>
                            </>
                        </ModalStyleMobile>
                    </>
                )
            }
            else {
                return (
                    <>

                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyleMobile>
                            <CloseButtonMobile>

                                <ModalCloseButtonMobile type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonMobile>
                            </CloseButtonMobile>
                            <>
                                <FormTitle className="d-flex justify-content-center mt-3">Edit Gender</FormTitle>
                                <hr />
                                <UsersForm onSubmit={GenderEdit}>
                                    <FormControl>
                                        <NameSurname>
                                            <NameFirst>
                                                <DivForLabelAndInput className="forDisable">
                                                    <LabelProfile >Gender SHQ</LabelProfile>
                                                    <InputProfileForm defaultValue={props?.dataToModal?.name_al} onChange={(e) => setEditGenderAl(e.target.value)} id="name_al" placeholder="Emri i sallonit" type="text" name="name_al" />
                                                </DivForLabelAndInput>
                                            </NameFirst>
                                            <NameFirst>
                                                <DivForLabelAndInput className="forDisable">
                                                    <LabelProfile >Gender ENG</LabelProfile>
                                                    <InputProfileForm defaultValue={props?.dataToModal?.name_en} onChange={(e) => setEditGenderEn(e.target.value)} id="name_al" placeholder="Emri i sallonit" type="text" name="name_al" />
                                                </DivForLabelAndInput>
                                            </NameFirst>
                                        </NameSurname>
                                        <ModalEditButtonServicess type={"submit"}>Edit</ModalEditButtonServicess>
                                    </FormControl>
                                </UsersForm>
                            </>
                        </ModalStyleMobile>
                    </>
                )
            }
        }
    }
    else
        return null
}

export default EditGender
