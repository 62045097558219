import React, { useState } from "react";
import ModalPortal from "../../../portal/ModalPortal";
import {
    UsersForm, FormControl, Label, Input, FormTitle, NameSurname, NameFirst, Surname, ModalAddButtonService,
    ModalDarkBackGround, ModalStyleService,
    ModalCloseButtonService, ModalStyleMobileResponsive, InputProfileForm,
    LabelProfile, DivForLabelAndInput, ModalAddButtonServices, CloseButton, ModalCloseButton
} from "../../styles/MainLayout"
import { CloseButtonMobile, ModalCloseButtonMobile } from "../../styles/MainLayoutResponsive"
import { useParams } from "react-router-dom";
import axios from "../../../axios";
import { toast } from "react-toastify";

const SubServiceModal = (props) => {
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    const { sid } = useParams();
    console.log("propsServices", props)
    //AXIOS
    const SubServiceAdd = (e) => {
        e.preventDefault();
        let body = {
            service_id: sid,
            name_al: e.target.name_al.value,
            name_en: e.target.name_en.value
        }
        axios
            .post(`/api/admin/sub_services`, body)
            .then(data => {
                props?.handleModalClose();
                // console.log(data.data)
                toast.success("New Service created")
                props?.getData();
            }
            ).catch(
                err => {
                    toast.error("Error creating new service!")
                    console.log(err)
                }
            )
    }
    //AXIOS
    const [editServiceName, setEditServiceName] = useState()
    const SubServiceEdit = (e) => {
        e.preventDefault();
        let body = {
            id: props?.data?.id,
            service_id: Number(sid),
            name_al: e.target.name_al.value,
            name_en: e.target.name_en.value
        }
        console.log("body", body)
        axios.put(`/api/admin/sub_services/update`, body)
            .then((data) => {
                props?.handleModalClose();
                props?.getData();
                console.log(data.data)
                toast.success("Edited sucessfully")
            }).catch(
                err => {

                    console.log(err)
                }
            )
    }
    console.log("PROPSAT", props)
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal>
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyleService>
                            <CloseButton>
                                <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                            </CloseButton>
                            {props?.type === "add" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Add Sub Service</FormTitle>
                                    <hr />
                                    <UsersForm onSubmit={SubServiceAdd}>
                                        <FormControl>
                                            <NameSurname>
                                                <NameFirst>
                                                    <DivForLabelAndInput className="forDisable">
                                                        <LabelProfile >Emri i Sherbimit</LabelProfile>
                                                        <InputProfileForm placeholder="Emri sherbimit" type="text" id="name_al" name="name_al" />
                                                    </DivForLabelAndInput>
                                                </NameFirst>
                                                <Surname>
                                                <DivForLabelAndInput className="forDisable">
                                                    <LabelProfile> Service Name </LabelProfile>
                                                    <InputProfileForm placeholder="Service name" type="text" id="name_en" name="name_en" />
                                                </DivForLabelAndInput>
                                                </Surname>
                                            </NameSurname>
                                            <br />
                                            <div className="d-flex justify-content-end">
                                            <ModalAddButtonServices type="submit">Add</ModalAddButtonServices>
                                            </div>
                                        </FormControl>
                                    </UsersForm>
                                </>
                                :
                                props?.type === "edit" ?
                                    <>
                                        <FormTitle className="d-flex justify-content-center mt-3">Edit Sub Service</FormTitle>
                                        <hr />
                                        <UsersForm onSubmit={SubServiceEdit}>
                                            <FormControl>
                                                <NameSurname>
                                                    <NameFirst>
                                                        <DivForLabelAndInput className="forDisable">
                                                            <LabelProfile >Emri i Sherbimit</LabelProfile>
                                                            <InputProfileForm defaultValue={props.data.name_al} id="name_al" placeholder="Emri sherbimit" type="text" name="name_al" />
                                                        </DivForLabelAndInput>
                                                    </NameFirst>
                                                    <Surname>
                                                <DivForLabelAndInput className="forDisable">
                                                        <LabelProfile> Service Name </LabelProfile>
                                                        <InputProfileForm defaultValue={props.data.name_en} placeholder="Service name" type="text" id="name_en" name="name_en" />
                                                </DivForLabelAndInput>
                                                    </Surname>
                                                </NameSurname>
                                                <br />
                                                <div className="d-flex justify-content-end">
                                            <ModalAddButtonServices type="submit">Edit</ModalAddButtonServices>
                                            </div>
                                            </FormControl>
                                        </UsersForm>
                                    </>
                                    :
                                    ""
                            }

                        </ModalStyleService>
                    </ModalPortal>
                </>
            )
        }
        //   ----MOBILE VIEW----- //
        else {
            return (
                <>

                    <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                    <ModalStyleMobileResponsive>
                        <CloseButtonMobile>

                            <ModalCloseButtonMobile type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonMobile>
                        </CloseButtonMobile>
                        {props?.type === "add" ?
                            <>
                                <FormTitle className="d-flex justify-content-center mt-3">Add Sub Service</FormTitle>
                                <hr />
                                <UsersForm onSubmit={SubServiceAdd}>
                                    <FormControl>
                                        <NameSurname>
                                            <NameFirst>
                                                <DivForLabelAndInput className="forDisable">
                                                    <LabelProfile >Emri i Sherbimit</LabelProfile>
                                                    <InputProfileForm placeholder="Emri sherbimit" type="text" id="name_al" name="name_al" />
                                                </DivForLabelAndInput>
                                            </NameFirst>

                                            <NameFirst>
                                                <DivForLabelAndInput className="forDisable">
                                                    <LabelProfile >Service Name </LabelProfile>
                                                    <InputProfileForm placeholder="Service name" type="text" id="name_en" name="name_en" />
                                                </DivForLabelAndInput>
                                            </NameFirst>

                                          
                                        </NameSurname>
                                        <br />
                                        <div className="d-flex justify-content-end mt-5 mb-2">
                                            <ModalAddButtonService type="submit">Add</ModalAddButtonService>
                                        </div>
                                    </FormControl>
                                </UsersForm>
                            </>
                            :
                            props?.type === "edit" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Edit Sub Service</FormTitle>
                                    <hr />
                                    <UsersForm onSubmit={SubServiceEdit}>
                                        <FormControl>
                                            <NameSurname>
                                                <NameFirst>
                                                    <DivForLabelAndInput className="forDisable">
                                                        <LabelProfile >Emri i Sherbimit</LabelProfile>
                                                        <InputProfileForm defaultValue={props.data.name_al} id="name_al" placeholder="Emri sherbimit" type="text" name="name_al" />
                                                    </DivForLabelAndInput>
                                                </NameFirst>
                                                {/* <Surname>
                                            <Label> Service Name </Label>
                                            <Input placeholder="Service name" type="text" name="name_en" />
                                        </Surname> */}
                                            </NameSurname>
                                            <br />
                                            <div className="d-flex justify-content-end mt-5 mb-2">
                                                <ModalAddButtonService type={"submit"}>Edit</ModalAddButtonService>
                                            </div>
                                        </FormControl>
                                    </UsersForm>
                                </>
                                :
                                ""
                        }

                    </ModalStyleMobileResponsive>
                </>
            )
        }
    }
    else
        return null
}

export default SubServiceModal
