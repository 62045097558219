import { SET_SELECTED_LANG, SET_LOGGED_IN, SET_SIDEBAR, SET_USER_DATA, SET_USER_TOKEN, SET_EXPIRED_TOKEN } from "./types";

export const setSelectedLang = (data) => {
    return {
        type: SET_SELECTED_LANG,
        data: data
    }
}
export const setLoggedIn = (data) => {
    return {
        type: SET_LOGGED_IN,
        data: data
    }
}
export const setExpiredToken = (data) => {
    return {
        type: SET_EXPIRED_TOKEN,
        data: data
    }
}
// export const setUserRole = (data) => {
//     return {
//         type: SET_USER_ROLE,
//         data: data
//     }
// }
// export const setUserName = (data) => {
//     return {
//         type: SET_USER_NAME,
//         data: data
//     }
// }
export const setSideBar = (data) => {
    return {
        type: SET_SIDEBAR,
        data: data
    }
}
export const setUserToken = (token) => {
    return {
        type: SET_USER_TOKEN,
        data: token
    }
}
// export const setSalonId = (id) => {
//     return {
//         type: SET_SALON_ID,
//         data: id
//     }
// }
export const setUserData = (data) => {
    return {
        type: SET_USER_DATA,
        data: data
    }
}
// export const setDataFilter = (data) => {
//     return {
//         type: SET_DATAFILTER,
//         data: data
//     }
// }
