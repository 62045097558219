import React, { useEffect } from "react";
import { useState } from "react";
import {AddButton , PaginationStyle, TableStyle} from "../styles/MainLayout"
import {CardStyle,AddButtonMobile} from "../styles/MainLayoutResponsive"
import MembershipModal from "../Modal/MembershipModals/MembershipModal";
import { setSideBar } from "../../redux/Functions/actions";
import { connect } from "react-redux";
import ReactPaginate from 'react-paginate';
import SearchInputHeader from "./SearchInputHeader";
import axios from "../../axios";
import MembershipDeleteModal from "../Modal/MembershipModals/MembershipDeleteModal";

const Membership = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [getmembershipData , setGetMembershipData] = useState()
    const [dataToModal, setDataToModal] = useState(null);
    const [isOpenDelete, setIsOpenDelete] = useState(false);

    //AXIOS -(get)
    const getMembership = () => {
        axios
          .get("/api/admin/subscriptions/all")
          .then((response) => {
            console.log("olaLabels", response.data);
            setGetMembershipData(response.data)
      setFilteredData(response.data)

          }).catch(err => {
            console.log(err)
          })
      }
      useEffect(() => {
        getMembership(); 
      }, [])
      
console.log("get", getmembershipData)

  // FILTER FUNCTION
  const [filteredData, setFilteredData] = useState();
  const filterByContent = async (filterValue) => {
    if (filterValue) {
      const filteredArray = getmembershipData.filter((el) =>
        el?.name?.toLowerCase().includes(filterValue?.toLowerCase())
      );
      setFilteredData(filteredArray);
    } else if (filterValue == "") {
      setFilteredData(getmembershipData);
    }
  };

// PAGINATION
    const [currentPage, setCurrentPage] = useState(0);
    const startIndex = currentPage * 9;
    const endIndex = startIndex + 9;
    const numPages = Math.ceil(filteredData?.length/ 9)

    const data = filteredData?.slice(startIndex, endIndex);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };
    //PAGINATION

  // FILTER FUNCTION

    const handleModalAdd = () => {
        setIsOpen(true);
        setModalType("add");
   
    };
    const handleModalEdit = (data) => {
        setIsOpen(true);
        setModalType("edit");
        setDataToModal(data)
    };
    const handleModalClose = () =>{
        setIsOpen(false);
        setIsOpenDelete(false);

    }
    const handleModalDelete = (data) => {
        setIsOpenDelete(true);
        setModalType("delete");
        setDataToModal(data)
      }
    console.log("setopen" , isOpen)
    

    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener('resize', () => {
        setViewportSize(window.innerWidth);
    });
    if (viewportSize > 800) {
        return (
            <>
                <SearchInputHeader filterByContent={filterByContent} />
            {isOpen === true ? (
            <MembershipModal 
            getNewMembership={() => getMembership()}
            isOpen={isOpen} 
            type={modalType} 
            handleModalClose={handleModalClose} 
            dataToModal={dataToModal}
            />
            ):(
            ""
            )}

        {isOpenDelete === true ? (
          <MembershipDeleteModal
            getNewMembership={() => getMembership()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal = {dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (

          ""
        )}
                <TableStyle collapse={props?.sidebar}>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                {/* <th scope="col-1">ID</th> */}
                                <th scope="col-3">Membership Name</th>
                                <th scope="col-6">Membership Description</th>
                                <th scope="col-6">Appointment Limit</th>
                                <th scope="col-6">Price</th>
                                <th scope="col-3">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map(el => {
                                return (
                                    <>
                                        <tr>
                                            {/* <th scope="row" className="col-1">{el.id}</th> */}
                                            <td className="col-4">{el.name}</td>
                                            <td className="col-6">{el.desc}</td>
                                            <td className="col-6">{el.appt_limit}</td>
                                            <td className="col-6">{el.price}</td>
                                            <td className="col-3">
                                                <div className="d-flex gap-3">
                                                    <i className="fa-regular fa-pen-to-square iconsForCursor"onClick={()=> handleModalEdit(el)} />
                                                    <i class="fa-regular fa-trash-can iconsForCursor" onClick={() => handleModalDelete(el)} />

                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                    </TableStyle>
                    <PaginationStyle collapse={props?.sidebar}>
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={numPages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination justify-content-center"}
                        activeClassName={"active"}
                    />
                </PaginationStyle>
                <AddButton>
                <i type={"button"} className="fa-solid fa-circle-plus fa-xl iconsForCursor" style={{color: "#FF5758"}}  onClick={()=> handleModalAdd()}></i>
                </AddButton>
            </>

        )
    }
    //RESPONSIVE VIEW
    else
        return (
            <>
                 <SearchInputHeader filterByContent={filterByContent} />
            {isOpen === true ? (
            <MembershipModal 
            getNewMembership={() => getMembership()}
            isOpen={isOpen} 
            type={modalType} 
            handleModalClose={handleModalClose} 
            dataToModal={dataToModal}
            />
            ):(
            ""
            )}

        {isOpenDelete === true ? (
          <MembershipDeleteModal
            getNewMembership={() => getMembership()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal = {dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (

          ""
        )}
                <div className="container">
                    {data?.map(el => {
                        return (
                            <>
                            <CardStyle>
                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-8">
                                            <ul className="list-group gap-3 pt-2 pb-2 ps-3">
                                                {/* <li class="list-group"><span><strong>ID</strong>: {el?.id}</span></li> */}
                                                <li class="list-group"><span><strong>Membership Name</strong>: {el.name}</span></li>
                                                <li class="list-group"><span><strong>Membership Description</strong> : {el.desc}</span></li>
                                                <li class="list-group"><span><strong>Appointment Limit</strong> : {el.appt_limit}</span></li>
                                                <li class="list-group"><span><strong>Price</strong> : {el.price}</span></li>

                                            </ul>
                                        </div>
                                        <div className="col-3 pt-2">
                                            <div className="d-flex gap-3">
                                                <i className="fa-regular fa-pen-to-square  pt-2 iconsForCursor" onClick={()=> handleModalEdit(el)}></i>
                                                <i class="fa-regular fa-trash-can pt-2 iconsForCursor" onClick={() => handleModalDelete(el)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </CardStyle>
                            </>
                        )
                    })}
                    <AddButtonMobile>
                    <i type={"button"} className="fa-solid fa-circle-plus d-flex flex-row-reverse fa-xl iconsForCursor" style={{color: "#FF5758"}} onClick={()=> handleModalAdd()}></i>
                    </AddButtonMobile>
                </div>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        sidebar: state?.data?.sidebar
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Membership);
// export default Membership;