export const MenuItems =[

        {
            id:"1",
            menu_name:"Users",
            menu_icon:"../../assets/menuIcons/users.png",
            path:"/",
        },
        {
            id:"2",
            "menu_name":"Memberships",
            "menu_icon":"../../assets/menuIcons/membership.png",
            "path":"/membership"

        },
        {
            "id":"4",
            "menu_name":"Salons",
            "menu_icon":"../../assets/menuIcons/salon.png",
            "path":"/salons"

        },
        // {
        //     "id":"5",
        //     "menu_name":"Services",
        //     "menu_icon":"../../menuIcons/scissors.png",
        //     // "submenuContent":["Clients" , "Staff" , "Salons" , "Appointments"],
        //     "path":"/services"

        // },
        {
            "id":"5",
            "menu_name":"Labels",
            "menu_icon":"../../assets/menuIcons/lang.png",
            "path":"/language"

        },
        {
            "id":"6",
            "menu_name":"Genders",
            "menu_icon":"../../assets/menuIcons/genders.png",
            "path":"/genders"

        }
    ]

    export const submenuItems =[
        {
                        id:1,
                        submenuTitle:"Statistic",
                        submenuLogo: "../../menuIcons/chart.png",
                        submenuContent:["Clients" , "Staff" , "Salons" , "Appointments"],
                        linkTo: "/test"
                    },
                    {
                        "id":1,
                        "submenuTitle":"Statistic",
                        "submenuLogo" : "../../menuIcons/chart.png",
                        "submenuContent":["Clients" , "Staff" , "Salons" , "Appointments"],
                        "linkTo": "/test"
                    },
                    {
                        "id":1,
                        "submenuTitle":"Statistic",
                        "submenuLogo" : "../../menuIcons/chart.png",
                        "submenuContent":["Clients" , "Staff" , "Salons" , "Appointments"],
                        "linkTo": "/test"
                    },
                    {
                        "id":1,
                        "submenuTitle":"Test",
                        "submenuLogo" : "../../menuIcons/chart.png",
                        "submenuContent":["test1" , "test2" , "test3" , "test4"],
                        "linkTo": "/test1"
                    }

    ]

    // {

    
    //     submenuItems :[
    //         {
    //             id:1,
    //             submenuTitle:"Statistic",
    //             submenuLogo: "../../menuIcons//chart.png",
    //             submenuContent:["Clients" , "Staff" , "Salons" , "Appointments"],
    //             linkTo: "/test"
    //         },
    //         {
    //             "id":1,
    //             "submenuTitle":"Statistic",
    //             "submenuLogo" : "../../menuIcons//chart.png",
    //             "submenuContent":["Clients" , "Staff" , "Salons" , "Appointments"],
    //             "linkTo": "/test"
    //         },
    //         {
    //             "id":1,
    //             "submenuTitle":"Statistic",
    //             "submenuLogo" : "../../menuIcons//chart.png",
    //             "submenuContent":["Clients" , "Staff" , "Salons" , "Appointments"],
    //             "linkTo": "/test"
    //         },
    //         {
    //             "id":1,
    //             "submenuTitle":"Test",
    //             "submenuLogo" : "../../menuIcons//chart.png",
    //             "submenuContent":["test1" , "test2" , "test3" , "test4"],
    //             "linkTo": "/test1"
    //         }
    //     ]
    
    // }


