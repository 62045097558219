import React from 'react'
import LoginForm from '../../components/form/LoginForm/LoginForm'
import Photo from "../../assets/images/termini-beauty-logo.png";
import { useNavigate } from "react-router";

import {
  LoginLayout,
  Loginform,
  Heading,
  FormLogin,
  InputEmail,
  InputPass,
  Buttons,
  Paragraf,
  Loginbutton,
  FormLayout,
  FormRegister,
  Image,
  Heading3,
  Label,
  Text,
  Paragraph,
  Labels,
  ButtonRegister,
  ButtonR,
  RegisterbuttonAtLogin
} from "../../components/styles/MainLayout";
const Login = () => {

  const navigate = useNavigate();

  const navigateToRegister = () => {
    // 👇️ navigate to /contacts
    window.location.href= 'http://teststore.termini.beauty/register';
  };
  return (
    <>
      <LoginLayout className="container">
        <Loginform data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="900">
          <Text>
            <Heading>Welcome back!</Heading>
            <Paragraph>Please enter your details</Paragraph>
          </Text>
          <LoginForm />
        </Loginform>
        <FormLayout>
          <FormRegister>
            <Image>
              <img src={Photo} alt="" />
            </Image>
            <Heading3>
              Don't have an <br /> account?
            </Heading3>
            <ButtonRegister>
              <ButtonR onClick={navigateToRegister}>Register your store now</ButtonR>
            </ButtonRegister>
          </FormRegister>
        </FormLayout>
      </LoginLayout>
    </>
  )
}

export default Login
