export const SET_SELECTED_LANG = "SET_SELECTED_LANG";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
// export const SET_USER_NAME = "SET_USER_NAME";
export const SET_SIDEBAR = "SET_SIDEBAR";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_EXPIRED_TOKEN = "SET_EXPIRED_TOKEN";

// export const SET_SALON_ID = "SET_SALON_ID";
// export const SET_USER_ROLE = "SET_USER_ROLE";
