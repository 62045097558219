import { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  SubMenu,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/4.png";
import terminilogo from "../../assets/images/logo-termini.png";
import "./sidebar.scss";
import { connect } from "react-redux";
import { setSideBar } from "../../redux/Functions/actions";
import { MenuItems  } from "../../data/sidebarData";
import harrisia_icon from "../../assets/images/company-logo/harrisia-icons.png";
import harrisia_logo from "../../assets/images/company-logo/harrisia-logo-01.png";
import {CgCloseR} from "react-icons/cg"
import {TfiMenuAlt} from "react-icons/tfi"
import axios from "../../axios"
import Scissor from "../../assets/images/scissors.png"
import ServiceModal from "../Modal/ServiceModal"
const Sidebari = (props) => {


  const location = useLocation();

  const [icon, setIcon] = useState(props?.sidebar === "" ? false : true);
  const [services , setServices] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [modalType, setModalType] = useState(false)
  const { collapseSidebar } = useProSidebar();

//AXIOS
const getServices = () =>{
  axios.get('/api/admin/services/all_ser').then(
      data => {
          console.log("serviset",data.data)
          setServices(data.data)
      }
  ).catch(
      err =>{
          console.log(err)
      }
  )
}

  const [viewportSize, setViewportSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setViewportSize(window.innerWidth);
  });

function handleOpenCollapse(){
  props.setSideBar("")
  setIcon(true)
  collapseSidebar(false);
}
function handleCloseCollapse(){
  props.setSideBar("collapsed")
  setIcon(false)
  collapseSidebar(true);
}
const handleModalAdd = () => {
  setIsOpen(true)
  setModalType("add")
}

const handleModalClose = () => {
  setIsOpen(false)
}


useEffect(()=> {
  getServices();
  if(props.sidebar === "")
  handleOpenCollapse()
  else
  handleCloseCollapse()
},[])
console.log("services",services)
  if (viewportSize > 800) {
    return (
   
      <div className="sidebar_layout">
           {isOpen === true ?
        <ServiceModal isOpen={isOpen} type={modalType} handleModalClose={handleModalClose}  />
        :
        ""
    }
        <span
          className={`collapse_sidebar_button ${icon == true ? "" : "collapsed"
            }`}
          onClick={() => {
            props.sidebar == "collapsed"
              ? 
              handleOpenCollapse()
              :
              handleCloseCollapse()
          }}
        >
          {icon === false ? (
            <TfiMenuAlt style={{fontSize:'25px'}} role="button"/>
          ) 
          : 
          (
            <CgCloseR style={{fontSize:'30px'}} role="button"/>
          )}
        </span>
        <div className="sidebar-custom">
          <Sidebar defaultCollapsed={props?.Sidebar === "collapsed" ? true : false}>
            <Menu className="sidebarMenu"
              menuItemStyles={{
                button: ({ level, active, disabled }) => {
                  // only apply styles on first level elements of the tree
                  if (level === 0)
                    return {
                      color: disabled ? "black" : "black",
                      backgroundColor: active ? "rgb(255, 87, 88)" : undefined,
                      borderRadius: active ? "12px" : undefined,

                    };
                },
              }}
            >
              <MenuItem
                className="borderheader"
                style={icon === true ? { marginLeft: "-30px", height: '72px', paddingBottom: '30px', width: '275px' } : {}}
                icon={
                  icon === false ? (
                    <img
                      src={logo}
                      alt="logo"
                      className="logo-collapsed"
                    />
                  ) : (
                    ""
                  )
                }
                disabled
              >
                <div className="logo_layout">
                  {icon === true ? (
                    <>
                      <img
                        src={terminilogo}
                        alt="termini. logo"
                        className="termini-logo-text"
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </MenuItem>
              <MenuItem
                style={{ marginLeft: "-15px" }}
                disabled
                className={icon === true ? "mt-5" : "mt-5 m-0"}
              >
                <strong className="text-secondary">General</strong>
              </MenuItem>
              {MenuItems?.map((el) => {
                return (
                  <>
                    <MenuItem
                      style={{ marginRight: '5px' }}
                      active={location.pathname === el?.path}
                      component={<Link to={el?.path} />}
                    >
                      {icon === false ? (
                        <img src={el?.menu_icon} className="menu-icons-collapsed" />
                      ) : (
                        <span>
                          <img src={el?.menu_icon} className="menu-icons" />
                          <strong>{el?.menu_name}</strong>
                        </span>
                      )}
                    </MenuItem>
                    {/* <SubMenu> */}

                    {el?.submenuContent?.map((elem) => {
                      return (
                        <MenuItem style={{ backgroundColor: "#d3d3d347" }}>
                        {elem}
                      </MenuItem>
                    );
                  })}
                  {/* </SubMenu> */}
                  </>
                );
              })}
              <div style={icon == false ? {width:'75%'} : null}>
              <SubMenu
                label={icon === true ? <strong>Services</strong> :""} 
                icon={<img src={Scissor} className="menu-icons-collapsed"/>}
                >
                  {/* <MenuItem onClick={()=> handleModalAdd()}><strong className="text-danger">Add Service <i class="fas fa-thin fa-circle-plus"></i></strong></MenuItem> */}
                  <Link to={"./AllServices/"}>
                  <MenuItem ><strong className="text-danger">All Services</strong></MenuItem>
                          </Link>
              {services?.map(el => {
                console.log("service data el" , el)
                return(
                  <MenuItem  
                  active={location.pathname === `/services/${el.id}`}
                  component={<Link to={`/services/${el.id}`} />}
                  icon={<img src={`${process.env.REACT_APP_API}/${el.img_url}`}className="menu-icons-collapsed"/>}
                  >
                    <strong>{el.name_al}</strong>
                  </MenuItem>
                  )
                })}
                </SubMenu>
                </div>
            </Menu>
            <div
              className={`sidebar_footer_layout ${props?.sidebar == "collapsed" ? "collapsed" : ""
                }`}
            >
              {props?.sidebar == "collapsed" ? (
                <div className="">
                  <img
                    className="harrisia_icon img-fluid col-12"
                    src={harrisia_icon}
                    alt="harrisia_logo"
                  />
                </div>
              ) : (
                <>
                  <div className="poweredBy">
                    <span className="poweredBy-text">

                      Powered by{" "}

                      <img
                        src={harrisia_logo}
                        alt="harrisia"
                        className="harrisia_logo"
                      ></img>

                    </span>
                  </div>
                </>
              )}
            </div>
          </Sidebar>
        </div>
      </div>
    );
  } else {
    return (
      <>
               {isOpen === true ?
        <ServiceModal isOpen={isOpen} type={modalType} handleModalClose={handleModalClose}  />
        :
        ""
    }
<div className="sidebar_layout">
        <span
          className={`collapse_sidebar_button ${icon == true ? "" : "collapsed"
            }`}
          onClick={() => {
            props.sidebar == "collapsed"
              ? props.setSideBar("")
              : props.setSideBar("collapsed");
            collapseSidebar();
            setIcon(!icon);
          }}
        >
          {icon === false ? (
            <TfiMenuAlt style={{fontSize:'25px'}} role="button"/>
          )
           :
           (
            <CgCloseR style={{fontSize:'30px'}} role="button"/>
          )}
        </span>
        <div className="sidebar-custom">
          <Sidebar>
            <Menu
              style={{
                display: "flex",
                backgroundColor: "white",
                padding: "5px"
              }}
              menuItemStyles={{
                button: ({ level, active, disabled }) => {
                  // only apply styles on first level elements of the tree
                  if (level === 0)
                    return {
                      color: disabled ? "black" : "black",
                      backgroundColor: active ? "rgb(255, 87, 88)" : undefined,
                      borderRadius: active ? "12px" : undefined,

                    };
                },
              }}
            >
              <MenuItem
                className="borderheader"
                style={icon === true ? { marginLeft: "-30px", height: '72px', paddingBottom: '30px', width: '275px' } : {}}
                icon={
                  icon === false ? (
                    <img
                      src={logo}
                      alt="logo"
                      className="logo-collapsed"
                    />
                  ) : (
                    ""
                  )
                }
                disabled
              >
                <div className="logo_layout">
                  {icon === true ? (
                    <>
                      <img
                        src={terminilogo}
                        alt="termini. logo"
                        className="termini-logo-text"
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </MenuItem>
              <MenuItem
                style={{ marginLeft: "-15px" }}
                disabled
                className={icon === true ? "mt-5" : "mt-5 m-0"}
              >
                <strong className="text-secondary">General</strong>
              </MenuItem>
              {MenuItems?.map((el) => {
                return (
                  <>
                    <MenuItem
                      style={{ marginRight: '5px' }}
                      active={location.pathname === el?.path}
                      component={<Link to={el?.path} />}
                    >
                      {icon === false ? (
                        <img src={el?.menu_icon} className="menu-icons-collapsed" />
                      ) : (
                        <span>
                          <img src={el?.menu_icon} className="menu-icons" />
                          <strong>{el?.menu_name}</strong>
                        </span>
                      )}
                    </MenuItem>
                  </>
                );
              })}
                <div style={icon == false ? {width:'75%'} : null}>
              <SubMenu
                label={icon === true ? <strong>Services</strong> :""} 
                icon={<img src={Scissor} className="menu-icons-collapsed"/>}
                >
              <Link to={"./AllServices/"}>
                  <MenuItem ><strong className="text-danger">All Services</strong></MenuItem>
                          </Link>
              {services?.map(el => {
                return(
                  <MenuItem  
                  active={location.pathname === `/services/${el.id}`}
                  component={<Link to={`/services/${el.id}`} />}
                  icon={<img src={`${process.env.REACT_APP_API}/${el.img_url}`}className="menu-icons-collapsed"/>}
                  >
                    <strong>{el.name_al}</strong>
                  </MenuItem>
                  )
                })}
                </SubMenu>
                </div>
            </Menu>
            <div
              className={`sidebar_footer_layout ${props?.sidebar == "collapsed" ? "collapsed" : ""
                }`}
            >
              {props?.sidebar == "collapsed" ? (
                <div>
                  <img
                    className="harrisia_icon img-fluid col-12"
                    src={harrisia_icon}
                    alt="harrisia_logo"
                  />
                </div>
              ) : (
                <>
                  <div className="poweredBy">
                    <span className="poweredBy-text">

                      Powered by{" "}

                      <img
                        src={harrisia_logo}
                        alt="harrisia"
                        className="harrisia_logo"
                      ></img>

                    </span>
                  </div>
                </>
              )}
            </div>
          </Sidebar>
        </div>
      </div>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state.data.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebari);

// export default Sidebari
