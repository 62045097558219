import React, {  useState } from "react";
import ModalPortal from "../../../portal/ModalPortal";
import {ModalStyle , ModalCloseButton,ModalDarkBackGround} from "../../styles/MainLayout"
import {ModalStyleMobile , ModalCloseButtonMobile} from "../../styles/MainLayoutResponsive"
import {WorkingHoursAdd} from "../../form/WorkingHours/WorkingHoursForm";

const WorkingHoursModal = (props) => {
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
console.log("PROPSAT Working Hours" , props.id)
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                <ModalPortal>
                    <ModalDarkBackGround onClick={()=> props.handleModalClose()}/>
                    <ModalStyle>
                    <ModalCloseButton type={"button"} onClick={()=> props.handleModalClose()}>X</ModalCloseButton>

                    {props?.type === "add"? 
                    <WorkingHoursAdd id={props.id}/>
                    :
                    props?.type === "edit" || props?.type === "view"?
                    "edit"
                    :
                    ""
                    }

                    </ModalStyle>
                </ModalPortal>
            </>
        )}
        //   ----MOBILE VIEW----- //
        else{
            return(
                <>
                <ModalDarkBackGround onClick={()=> props.handleModalClose()}/>
                <ModalStyleMobile>
                <ModalCloseButtonMobile type={"button"} onClick={()=> props?.handleModalClose()}>X</ModalCloseButtonMobile>
                {props?.type === "add"? 
                   <WorkingHoursAdd/>
                    :
                    props?.type === "edit" || props?.type === "view"?
                    <WorkingHoursAdd/>
                    :
                    ""
                    }

                    </ModalStyleMobile>
                </>
            )
        }
    }
    else
        return null
}

export default WorkingHoursModal
