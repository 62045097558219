import { SET_SELECTED_LANG, SET_LOGGED_IN, SET_SIDEBAR, SET_USER_DATA, SET_USER_TOKEN, SET_EXPIRED_TOKEN } from "./types";

const INITIAL_STATE = {
    selected_lang: 'AL',
    logged_in: false,
    sidebar: "",
    username: "",
    user_data: null,
    role: "",
    userToken: ""
};
const initialReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SELECTED_LANG:
            return { ...state, selected_lang: action.data }
        case SET_LOGGED_IN:
            return { ...state, logged_in: action.data }
        case SET_SIDEBAR:
            return { ...state, sidebar: action.data }
        case SET_USER_DATA:
            return { ...state, user_data: action.data }
            case SET_EXPIRED_TOKEN:
                return { ...state, expire_token: action.data }
        case SET_USER_TOKEN:
            return { ...state, userToken: action.data }
        default: return state;

    }
};

export default initialReducer;