import React, { } from 'react'
import { useState } from 'react';
import ModalPortal from "../../../portal/ModalPortal";
import { ModalDarkBackGround,FormTitle, DeleteModal, DeleteModalButton,ModalStyleDelete,
     ModalDeleteButton,ModalCancelButton, FormTitleResponsive } from "../../../components/styles/MainLayout";
import axios from "../../../axios"
import { toast } from 'react-toastify';

const SalonServiceDeleteModal = (props) => {
    //AXIOS (Delete)
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });

    const handleDeleteSalonService = () => {
        axios.delete(`/api/admin/salon_services/${props?.dataToModal?.id}`)
          .then((res) => {
            toast.success("u fshi")
            props?.getSingleSalonAddress()
            props?.getSalonAddressService()
            props?.getServices()
            props?.handleModalClose();
          })
          .catch(err => toast.error(err))
       
      }
    //AXIOS
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal >
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyleDelete>
                            <DeleteModal className='mt-5'>
                                <h2>Confirmation</h2>
                                <br/>
                                <FormTitle className="d-flex justify-content-center mt-3">Are you sure you want to remove "{props?.dataToModal?.name_al}"? </FormTitle>
                                <p className='a'>Do you really want to remove this service? This <br />process cannot be undone.</p>
                            </DeleteModal>
                                <DeleteModalButton className=' mt-5'>
                                    <ModalCancelButton onClick={() => props.handleModalClose()}>Close</ModalCancelButton>
                                    <ModalDeleteButton onClick={(e) => handleDeleteSalonService(e)} >Remove</ModalDeleteButton>
                                </DeleteModalButton>
                        </ModalStyleDelete>
                    </ModalPortal>
                </>
            )
        }
        else {
            return (
                <>
                <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                <ModalStyleDelete>
                            <DeleteModal className='mt-5'>
                                <h2>Confirmation</h2>
                                <br/>
                                <FormTitleResponsive className="d-flex justify-content-center mt-3">Are you sure you want to cancel "<strong> {props?.dataToModal?.name}</strong>"? </FormTitleResponsive>
                                <p className='a'>Do you really want to cancel this appoint <br/> ment? This process cannot be undone.</p>
                            </DeleteModal>
                                <DeleteModalButton className=' mt-4'>
                                    <ModalCancelButton onClick={() => props.handleModalClose()}>Close</ModalCancelButton>
                                    <ModalDeleteButton onClick={(e) => handleDeleteSalonService(e)} >Remove</ModalDeleteButton>
                                </DeleteModalButton>
                        </ModalStyleDelete>
                </>
            )
        }
    }
    else
        return null
}

export default SalonServiceDeleteModal
