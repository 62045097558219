import React, { useEffect, useState } from "react";
import ModalPortal from "../../../portal/ModalPortal";
import {
    UsersForm, FormControl, Label, Input, ModalAddButton, FormTitle,
    SelectOption, SelectOptionBox, NameFirst, ModalStyle, ModalCloseButton,NameSurnameDiv,
    NameSurname, Surname, ModalDarkBackGround, EditFormControl, UserEditForm, ModalEditButton,
    DivForLabelAndInputUser,LabelProfile, InputProfileForm, ModalAddButtonUsers, DivForLabelAndInputName, CloseButton, DivForLabelAndInput
} from "../../styles/MainLayout"
import { ModalStyleMobile, ModalCloseButtonMobile,CloseButtonMobile } from "../../styles/MainLayoutResponsive"
import axios from '../../../axios';
import { toast } from 'react-toastify';
import sign from "jwt-encode"


const Modal = (props) => {
    const [roles, setRoles] = useState([])
    const [subs, setSubs] = useState([])
    const [salons, setSalons] = useState([])
    const [selectedRole, setSelectedRole] = useState()
    const secretKey = process.env.REACT_APP_SECRET_KEY


    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });


    const getSubs = () => {
        axios.get('/api/admin/subscriptions/all').then(
            data => {
                console.log(data)
                setSubs(data.data)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }
    const getData = () => {
        axios.get('/api/admin/user_roles/all').then(data => {
            console.log('allusers get req', data.data);
            setRoles(data.data)
        }).catch(err => console.log(err));
    }
    const getSalons = () => {
        axios.get('/api/admin/salon/all').then(data => {
            console.log('allusers get req', data.data);
            setSalons(data.data)
        }).catch(err => console.log(err));
    }
    const addUser = (e) => {
        let passEnc = sign(e.target.password.value, secretKey)
        console.log('eventGashji', passEnc)
        e.preventDefault();
        let body = {
            name: e?.target?.first_name?.value,
            surname: e?.target?.last_name?.value,
            email: e?.target?.email?.value,
            password: passEnc,
            username: e?.target?.username?.value,
            // salon_id : e?.target?.salon_id?.value,
            gender: e?.target?.gender?.value,
            phone_number: e?.target?.phone_number?.value,
            role_id: e?.target?.role_id.value,
            // subscription_id : e?.target?.subscription_id.value,
            default_language: e?.target?.default_language.value,
            created_by: 1
        }
        console.log('postdata', body)
        axios.post("/api/admin/users/", body)
            .then(data => {
                console.log("users added" + data.data)
                props?.handleModalClose();
                props.getNewUsers()
                toast.success("User Added Sucesfully!")
            }).catch(err => {
                console.log('error gashi', err)
                toast.error(err?.response?.data)
            })
    }
    useEffect(() => {

        getSubs()
        getData()
        getSalons()
    }, [])
    console.log("roles",roles)
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal>
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyle>
                            <CloseButton>
                            <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                            </CloseButton>


                            {props?.type === "add" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Add User</FormTitle>
                                  <hr/>
                                    <UsersForm onSubmit={addUser}>
                                        <FormControl>
                                            <NameSurnameDiv className="row">
                                                <NameFirst className="col-md-6">
                                                <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile >First Name</LabelProfile>
                                            <InputProfileForm required id="first_name" name="first_name" placeholder="Your first name" type="text" />
                                                </DivForLabelAndInputUser>
                                                </NameFirst>
                                                
                                                <Surname className="col-md-6">
                                                <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile >Last Name</LabelProfile>
                                            <InputProfileForm required id="last_name" name="last_name" placeholder="Your last name" type="text" />
                                            </DivForLabelAndInputUser>
                                                </Surname>
                                            </NameSurnameDiv>

                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile >Username</LabelProfile>
                                            <InputProfileForm required id="username" name="username" placeholder="Username" type="text" />
                                            </DivForLabelAndInput>
                                           
                                            <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile >Phone Number</LabelProfile>
                                            <InputProfileForm required id="phone_number" name="phone_number" placeholder="Your phone number" type="number" />
                                            </DivForLabelAndInputUser>

                                            <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile> Email </LabelProfile>
                                            <InputProfileForm required id="email" name="email" placeholder="Your email" type="email" />
                                            </DivForLabelAndInputUser >
                                            <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile>Password</LabelProfile>
                                            <InputProfileForm required id="password" name="password" placeholder="*****" type="password" />
                                            </DivForLabelAndInputUser >

                                            <SelectOptionBox className="row m-0">
                                            <DivForLabelAndInputUser className=" col-md-6">
                                                <SelectOption required id="role_id" name="role_id">
                                                    <option>Select Role</option>
                                                    {roles.filter(el=> el.id != 4 ).map(el => {
                                                        console.log('roler ele', el)
                                                        return (
                                                            <>
                                                                <option onClick={() => setSelectedRole(el?.id)} value={el?.id} >{el?.role_al}</option>
                                                            </>
                                                        )
                                                    })}
                                                </SelectOption>
                                                    </DivForLabelAndInputUser>

                                                {selectedRole == 4 &&
                                                    <SelectOption required id="role_id" name="role_id">
                                                        <option>Assign a salon to this worker</option>
                                                        {roles.map(el => {
                                                            return (
                                                                <>
                                                                    <option onClick={() => setSelectedRole()} value={el?.id} >{el?.role_al}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </SelectOption>}
                                                {
                                                    console.log('selectedRole', selectedRole)
                                                    
                                                }
                                            <DivForLabelAndInputUser className=" col-md-6">
                                                <SelectOption required id="gender" name="gender">
                                                    <option>Select Gender</option>
                                                    <option value={1}>Male</option>
                                                    <option value={2}>Female</option>
                                                </SelectOption>
                                            </DivForLabelAndInputUser>

                                            <DivForLabelAndInputUser className=" col-md-6">
                                                <SelectOption required id="default_language" name="default_language">
                                                    <option>Select Language</option>
                                                    <option value={1}>Shqip</option>
                                                    <option value={2}>English</option>
                                                </SelectOption>
                                            </DivForLabelAndInputUser>
                                            </SelectOptionBox>
                                        <div className="d-flex justify-content-end mt-5 mb-5">
                                        <ModalAddButtonUsers type="submit" >Add</ModalAddButtonUsers>
                                        </div>
                                        </FormControl>
                                    </UsersForm>
                                </>
                                :
                                props?.type === "edit" || props?.type === "view" ?
                                    <>
                                        <FormTitle className="d-flex justify-content-center mt-3">View User</FormTitle>
                                        <hr/>
                                        <UserEditForm>
                                            <EditFormControl>
                                                <NameSurname>

                                                <NameFirst>
                                            <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile >First Name</LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.name} />
                                            </DivForLabelAndInputUser>
                                            </NameFirst>


                                            <Surname>
                                            <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile> Last Name </LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.surname} />
                                            </DivForLabelAndInputUser>
                                            </Surname>  

                                                </NameSurname>

                                                 <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile> Phone Number </LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.phone_number} />
                                        </DivForLabelAndInputUser>

                                                  <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile> Email </LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.email} />
                                        </DivForLabelAndInputUser>
                                              
                                                <SelectOptionBox className="gap-3 d-flex mt-2">
                                        {/* <DivForLabelAndInputUser className="forDisable">

                                                    <SelectOption defaultValue={props?.data?.salon_id} required id="salon_id" name="salon_id">
                                                        <option>Select Salon</option>
                                                        {salons?.map(el => {
                                                            return (
                                                                <>
                                                                    <option value={el?.id}>{el?.name_al}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </SelectOption>
                                                        </DivForLabelAndInputUser> */}

                                        <DivForLabelAndInputUser className="forDisable">

                                                    <SelectOption required id="role_id" name="role_id">
                                                        <option>Select Role</option>
                                                        {roles?.filter(el => el.id != 4).map(el => {
                                                            return (
                                                                <>
                                                                    <option value={el?.id}>{el?.role_al}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </SelectOption>
                                                        </DivForLabelAndInputUser>

                                        <DivForLabelAndInputUser className="forDisable">

                                                    <SelectOption required id="gender" name="gender">
                                                        <option>Select Gender</option>
                                                        <option value={1}>Male</option>
                                                        <option value={2}>Female</option>
                                                    </SelectOption>
                                        </DivForLabelAndInputUser>
                                        <DivForLabelAndInputUser className="forDisable">

                                                    <SelectOption required id="default_language" name="default_language">
                                                        <option>Select Language</option>
                                                        <option value={1}>Shqip</option>
                                                        <option value={2}>English</option>
                                                    </SelectOption>
                                        </DivForLabelAndInputUser>
                                                </SelectOptionBox>



                                                {/* <Label>Created_at </Label>
                                                                 <Input placeholder="...." type= "text"/> */}
                                                {props?.type === "edit" ?
                                                    <ModalEditButton type={"button"}>Save</ModalEditButton>
                                                    :
                                                    ""
                                                }
                                            </EditFormControl>
                                        </UserEditForm>
                                    </>
                                    :
                                    ""
                            }

                        </ModalStyle>
                    </ModalPortal>
                </>
            )
        }
        //   ----MOBILE VIEW----- //
        else {
            return (
                <>
                    <ModalDarkBackGround onClick={() => props.handleModalClose()} />

                    <ModalStyleMobile>
                        <CloseButtonMobile>

                        <ModalCloseButtonMobile type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonMobile>
                        </CloseButtonMobile>
                        {props?.type === "add" ?
                            <>
                                <FormTitle className="d-flex justify-content-center mt-3">Add User</FormTitle>
                                <hr/>
                                <UsersForm onSubmit={addUser}>
                                    <FormControl>
                                        <NameSurname>
                                            <NameFirst>
                                            <DivForLabelAndInputName className="forDisable">
                                            <LabelProfile >First Name</LabelProfile>
                                            <InputProfileForm required id="first_name" name="first_name" placeholder="Your first name" type="text" />
                                                </DivForLabelAndInputName>
                                            </NameFirst>
                                            <Surname>
                                            <DivForLabelAndInputName className="forDisable">
                                            <LabelProfile >Last Name</LabelProfile>
                                            <InputProfileForm required id="last_name" name="last_name" placeholder="Your last name" type="text" />
                                            </DivForLabelAndInputName>
                                            </Surname>
                                        </NameSurname>
                                        <DivForLabelAndInput className="forDisable">
                                            <LabelProfile >Username</LabelProfile>
                                            <InputProfileForm required id="username" name="username" placeholder="Username" type="text" />
                                            </DivForLabelAndInput>
                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile >Phone Number</LabelProfile>
                                            <InputProfileForm required id="phone_number" name="phone_number" placeholder="Your phone number" type="number" />
                                            </DivForLabelAndInputUser>
                                            <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile> Email </LabelProfile>
                                            <InputProfileForm required id="email" name="email" placeholder="Your email" type="email" />
                                            </DivForLabelAndInputUser>
                                            <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile>Password</LabelProfile>
                                            <InputProfileForm required id="password" name="password" placeholder="*****" type="password" />
                                            </DivForLabelAndInputUser >
                                            <SelectOptionBox className="gap-3 d-flex mt-2">
                                        {/* <DivForLabelAndInputUser className="forDisable">

                                                    <SelectOption defaultValue={props?.data?.salon_id} required id="salon_id" name="salon_id">
                                                        <option>Select Salon</option>
                                                        {salons?.map(el => {
                                                            return (
                                                                <>
                                                                    <option value={el?.id}>{el?.name_al}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </SelectOption>
                                                        </DivForLabelAndInputUser> */}

                                        <DivForLabelAndInputUser className="forDisable">
                                                    <SelectOption required id="role_id" name="role_id">
                                                        <option>Select Role</option>
                                                        {roles?.filter(el => el.id != 4).map(el => {
                                                            return (
                                                                <>
                                                                    <option value={el?.id}>{el?.role_al}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </SelectOption>
                                                        </DivForLabelAndInputUser>

                                        <DivForLabelAndInputUser className="forDisable">
                                                    <SelectOption required id="gender" name="gender">
                                                        <option>Select Gender</option>
                                                        <option value={1}>Male</option>
                                                        <option value={2}>Female</option>
                                                    </SelectOption>
                                        </DivForLabelAndInputUser>

                                        <DivForLabelAndInputUser className="forDisable">
                                                    <SelectOption required id="default_language" name="default_language">
                                                        <option>Select Language</option>
                                                        <option value={1}>Shqip</option>
                                                        <option value={2}>English</option>
                                                    </SelectOption>
                                        </DivForLabelAndInputUser>

                                                </SelectOptionBox>
                                    <div className="d-flex justify-content-end mt-2 mb-2">
                                    <ModalEditButton type="submit" >Add</ModalEditButton>
                                    </div>
                                    </FormControl>
                                </UsersForm>
                            </>
                            :
                            props?.type === "edit" || props?.type === "view" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">View User</FormTitle>
                                    <hr/>
                                    <UsersForm onSubmit={addUser}>
                                        <FormControl>
                                            <NameSurname>
                                                <NameFirst>
                                            <DivForLabelAndInputName className="forDisable">
                                                    <LabelProfile >First Name</LabelProfile>
                                                    <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.name} />
                                            </DivForLabelAndInputName>
                                                </NameFirst>

                                                <Surname>

                                                <DivForLabelAndInputName className="forDisable">
                                                    <LabelProfile >Last Name</LabelProfile>
                                                    <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.surname} />
                                                </DivForLabelAndInputName>

                                                </Surname>
                                            </NameSurname>
                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile >Phone Number</LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.phone_number} />
                                        </DivForLabelAndInputUser>

                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile> Email </LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.email} />
                                        </DivForLabelAndInputUser>
                                        
                                        <SelectOptionBox className="gap-3 d-flex mt-2">
                                        {/* <DivForLabelAndInputUser className="forDisable">

                                                    <SelectOption defaultValue={props?.data?.salon_id} required id="salon_id" name="salon_id">
                                                        <option>Select Salon</option>
                                                        {salons?.map(el => {
                                                            return (
                                                                <>
                                                                    <option value={el?.id}>{el?.name_al}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </SelectOption>
                                                        </DivForLabelAndInputUser> */}

                                        <DivForLabelAndInputUser className="forDisable">
                                                    <SelectOption required id="role_id" name="role_id">
                                                        <option>Select Role</option>
                                                        {roles?.map(el => {
                                                            return (
                                                                <>
                                                                    <option value={el?.id}>{el?.role_al}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </SelectOption>
                                                        </DivForLabelAndInputUser>

                                        <DivForLabelAndInputUser className="forDisable">
                                                    <SelectOption required id="gender" name="gender">
                                                        <option>Select Gender</option>
                                                        <option value={1}>Male</option>
                                                        <option value={2}>Female</option>
                                                    </SelectOption>
                                        </DivForLabelAndInputUser>

                                        <DivForLabelAndInputUser className="forDisable">
                                                    <SelectOption required id="default_language" name="default_language">
                                                        <option>Select Language</option>
                                                        <option value={1}>Shqip</option>
                                                        <option value={2}>English</option>
                                                    </SelectOption>
                                        </DivForLabelAndInputUser>

                                                </SelectOptionBox>
                                        </FormControl>
                                    </UsersForm>
                                
                                    </>

                                    :
                                    ""
                        }
                    </ModalStyleMobile>
                </>
            )
        }
    }
    else
        return null
}
export default Modal