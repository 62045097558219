import React, { useEffect, useState } from "react";
import ModalPortal from "../../../portal/ModalPortal";
import {
    ModalStyle, ModalCloseButton, ModalDarkBackGround,
    FormTitle, UsersForm, FormControl, Label,
    Input, ModalAddButton, LabelInputModal, LabelInputModalOne, DivForLabelAndInputUser,
    LabelProfile, InputProfileForm, ModalAddButtonUsers, ModalEditButton, ModalEditButtonAdd,CloseButton
} from "../../styles/MainLayout"
import { ModalStyleMobile, ModalCloseButtonMobile, CloseButtonMobile } from "../../styles/MainLayoutResponsive"
import axios from "../../../axios";
import { toast } from "react-toastify";

const MembershipModal = (props) => {
    console.log("hejPla", props)
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [appt_limit, setAppt_limit] = useState("");
    const [price, setPrice] = useState("");
    const [oneTime, setOneTime] = useState(false);
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });


    // const { Addmembership, setAddMembership } = useState();
    //AXIOS (Add)
    const body = {
        name: name,
        desc: desc,
        appt_limit: appt_limit,
        price: price
    };
    console.log("olaola", body)

    const addMembership = (e) => {
        e.preventDefault();
        setOneTime(true);
        axios
            .post("/api/admin/subscriptions", body)
            .then((response) => {
                props?.handleModalClose();
                props?.getNewMembership()
                toast.success("New membership created!")
                console.log("olaola", response)
                setOneTime(false);
            }).catch(err => {
                toast.error("Error creating new membership!")

                console.log(err)
            })
    }
    //AXIOS (Edit)
    const editBody = {
        id: props?.dataToModal?.id,
        name: name,
        desc: desc,
        appt_limit: Number(appt_limit),
        price: Number(price)
    }

    const handleEdit = (e) => {
        e.preventDefault();
        axios
            .put(`/api/admin/subscriptions/update`, editBody)
            .then((response) => {
                console.log("olaLabels", response);
                props?.handleModalClose();
                props?.getNewMembership();
                toast.success("subscriptions edited sucessfully.")
            }).catch(err => {
                toast.error(err)
                console.log(err)
            })
    };


    const handleDelete = (e) => {
        e.preventDefault()
        axios
            .delete(`/api/admin/subscriptions/${props?.dataToModal?.id}`)
            .then((response) => {
                console.log("olaLabels", response.data);
                toast.success("Label deleted sucessfully.")
                props?.handleModalClose();

                props?.getNewMembership();
            }).catch(err => {
                toast.error(err)
                console.log(err)
            })
    };
    useEffect(() => {
        setName(props?.dataToModal?.name)
        setDesc(props?.dataToModal?.desc)
        setAppt_limit(props?.dataToModal?.appt_limit)
        setPrice(props?.dataToModal?.price)
    }, [props?.isOpen]);

    console.log("PROPSAT", props)
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal>
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyle>
                            <CloseButton>
                                <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                            </CloseButton>
                            {props?.type === "add" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Add Membership</FormTitle>
                                    <hr />
                                    <UsersForm onSubmit={(e) => addMembership(e)}>
                                        <FormControl>
                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile >Membership Name</LabelProfile>
                                                <InputProfileForm placeholder="Your name" type="text" required onChange={(e) => setName(e.target.value)} />
                                            </DivForLabelAndInputUser>


                                            <LabelInputModal className="row">
                                                <LabelInputModalOne className="col-md-6">
                                                    <DivForLabelAndInputUser className="forDisable">
                                                        <LabelProfile>Appointment Limit</LabelProfile>
                                                        <InputProfileForm placeholder="Appointment-Limit" type="number" required onChange={(e) => setAppt_limit(e.target.value)} />
                                                    </DivForLabelAndInputUser>
                                                </LabelInputModalOne>

                                                <LabelInputModalOne className="col-md-6">
                                                    <DivForLabelAndInputUser className="forDisable">
                                                        <LabelProfile>Price €</LabelProfile>
                                                        <InputProfileForm placeholder="Price" type="number" required onChange={(e) => setPrice(e.target.value)} />
                                                    </DivForLabelAndInputUser>
                                                    <br />
                                                </LabelInputModalOne>
                                            </LabelInputModal>
                                            <DivForLabelAndInputUser className="forDisable">

                                                <LabelProfile>Membership Description </LabelProfile>
                                                <textarea className="form-control" cols="30" rows="3" placeholder="Description..." required onChange={(e) => setDesc(e.target.value)}></textarea>
                                            </DivForLabelAndInputUser>
                                            <div className="d-flex justify-content-end mt-5 mb-5">
                                            <ModalEditButtonAdd type="submit" disabled={oneTime === true ? true : false} className={oneTime !== true ? "oneTime" : ""}>Add</ModalEditButtonAdd>
                                            </div>
                                        </FormControl>
                                    </UsersForm>
                                </>
                                :
                                props?.type === "edit" ?
                                    <>
                                        <FormTitle className="d-flex justify-content-center mt-3">Edit Membership</FormTitle>
                                        <hr />
                                        <UsersForm type={props?.type} onSubmit={handleEdit}>
                                            <FormControl>

                                                <DivForLabelAndInputUser className="forDisable">
                                                    <LabelProfile >Membership Name</LabelProfile>
                                                    <InputProfileForm type="text" required defaultValue={props?.dataToModal?.name} onChange={(e) => setName(e.target.value)} />
                                                </DivForLabelAndInputUser>
                                                <LabelInputModal className="row">
                                                    <LabelInputModalOne className="col-md-6">

                                                        <DivForLabelAndInputUser className="forDisable">
                                                            <LabelProfile>Appointment Limit</LabelProfile>
                                                            <InputProfileForm type="number" required defaultValue={props?.dataToModal?.appt_limit} onChange={(e) => setAppt_limit(e.target.value)} />
                                                        </DivForLabelAndInputUser>
                                                    </LabelInputModalOne>
                                                    <LabelInputModalOne className="col-md-6">

                                                        <DivForLabelAndInputUser className="forDisable">
                                                            <LabelProfile>Price €</LabelProfile>
                                                            <InputProfileForm step="0.01" type="number" required defaultValue={props?.dataToModal?.price} onChange={(e) => setPrice(e.target.value)} />
                                                        </DivForLabelAndInputUser>
                                                        <br />
                                                    </LabelInputModalOne>
                                                </LabelInputModal>
                                                <DivForLabelAndInputUser className="forDisable">
                                                    <LabelProfile>Membership Description </LabelProfile>
                                                    <textarea defaultValue={props?.dataToModal?.desc} required onChange={(e) => setDesc(e.target.value)} className="form-control" cols="30" rows="3"></textarea>
                                                </DivForLabelAndInputUser>
                                                <div className="d-flex justify-content-end mt-5 mb-5">
                                                <ModalEditButtonAdd type="submit">Edit</ModalEditButtonAdd>
                                                </div>
                                            </FormControl>
                                        </UsersForm>
                                    </>
                                    :
                                    props?.type === "delete" ?
                                        <>
                                            <FormTitle className="d-flex justify-content-center mt-3">Are you sure you want to delete "{props?.dataToModal?.name}" </FormTitle>
                                            <UsersForm>
                                                <FormControl>
                                                    <Label >Membership Name</Label>
                                                    <Input type="text" required defaultValue={props?.dataToModal?.name} disabled={props?.type === "delete"} />
                                                    <Label>Membership Description </Label>
                                                    <textarea defaultValue={props?.dataToModal?.desc} disabled={props?.type === "delete"} required className="form-control" cols="30" rows="3"></textarea>
                                                    <Label>Appointment Limit</Label>
                                                    <Input type="number" required defaultValue={props?.dataToModal?.appt_limit} disabled={props?.type === "delete"} />
                                                    <Label>Price €</Label>
                                                    <Input type="number" required defaultValue={props?.dataToModal?.price} />
                                                    <ModalAddButton onClick={(e) => handleDelete(e)}>Delete</ModalAddButton>
                                                </FormControl>
                                            </UsersForm>
                                        </>
                                        :
                                        ""
                            }

                        </ModalStyle>
                    </ModalPortal>
                </>
            )
        }
        //   ----MOBILE VIEW----- //
        else {
            return (
                <>
                    <ModalDarkBackGround onClick={() => props.handleModalClose()} />

                    <ModalStyleMobile>
                        <CloseButtonMobile>

                        <ModalCloseButtonMobile type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonMobile>
                        </CloseButtonMobile>
                        {props?.type === "add" ?

                            <>
                                <FormTitle className="d-flex justify-content-center mt-3">Add Membership</FormTitle>
                                <hr />
                                <UsersForm onSubmit={(e) => addMembership(e)}>
                                    <FormControl>
                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile >Membership Name</LabelProfile>
                                            <InputProfileForm placeholder="Your name" type="text" required onChange={(e) => setName(e.target.value)} />
                                        </DivForLabelAndInputUser>
                                        <LabelProfile>Membership Description </LabelProfile>
                                        <textarea className="form-control" cols="30" rows="3" placeholder="Description..." required onChange={(e) => setDesc(e.target.value)}></textarea>
                                        <br />
                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile>Appointment Limit</LabelProfile>
                                            <InputProfileForm placeholder="Appointment-Limit" type="number" required onChange={(e) => setAppt_limit(e.target.value)} />
                                        </DivForLabelAndInputUser>
                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile>Price €</LabelProfile>
                                            <InputProfileForm placeholder="Price" type="number" required onChange={(e) => setPrice(e.target.value)} />
                                        </DivForLabelAndInputUser>
                                        <br />
                                        <div className=" d-flex justify-content-end">

                                        <ModalEditButton type="submit">Add</ModalEditButton>
                                        </div>
                                    </FormControl>
                                </UsersForm>
                            </>
                            :
                            props?.type === "edit" || props?.type === "view" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Edit Membership</FormTitle>
                                    <hr />

                                    <UsersForm type={props?.type} onSubmit={handleEdit}>
                                        <FormControl>

                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile >Membership Name</LabelProfile>
                                                <InputProfileForm type="text" required defaultValue={props?.dataToModal?.name} onChange={(e) => setName(e.target.value)} />
                                            </DivForLabelAndInputUser>

                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile>Appointment Limit</LabelProfile>
                                                <InputProfileForm type="number" required defaultValue={props?.dataToModal?.appt_limit} onChange={(e) => setAppt_limit(e.target.value)} />
                                            </DivForLabelAndInputUser>


                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile>Price €</LabelProfile>
                                                <InputProfileForm type="number" required defaultValue={props?.dataToModal?.price} onChange={(e) => setPrice(e.target.value)} />
                                            </DivForLabelAndInputUser>

                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile>Membership Description </LabelProfile>
                                                <textarea defaultValue={props?.dataToModal?.desc} required onChange={(e) => setDesc(e.target.value)} className="form-control" cols="30" rows="3"></textarea>
                                            </DivForLabelAndInputUser>
                                            <br />
                                            <div className="d-flex justify-content-end">
                                            <ModalEditButton type="submit">Edit</ModalEditButton>
                                            </div>
                                        </FormControl>
                                    </UsersForm>
                                </>
                                :


                                ""
                        }


                    </ModalStyleMobile>
                </>
            )
        }
    }
    else
        return null
}

export default MembershipModal
