import React from 'react'
import LanguageForm from '../../components/form/LanguageForm/LanguageForm'

const Language = () => {
  return (
   <>
 <LanguageForm/>
   </>
  )
}

export default Language
