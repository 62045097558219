import styled, { css } from "styled-components";

export const CalendarStyleResponsive = styled.div`
margin-top:15px;
position: fixed;
border-top: 6px solid #FF5758;
border-radius: 12px;
background-color: rgb(229 229 229);
width: 95%;
left:2.5%;
height: 70%;
padding: 2%;
`

export const CardStyle = styled.div`
border-top: 5px solid #FF5758;
box-shadow: 4px 2px 11px 3px rgba(0,0,0,0.3);
-webkit-box-shadow: 4px 2px 11px 3px rgba(0,0,0,0.3);
-moz-box-shadow: 4px 2px 11px 3px rgba(0,0,0,0.3);
border-radius: 15px;
background-color:white;
margin-top:10px;
width: 76%;
    margin-left: 23%;
    font-size: 15px ;
`
export const AddButtonMobile = styled.div`
top: unset;
bottom: 6%;
right: 5%;
position:fixed;
font-size:25px;
`
export const ModalStyleMobile = styled.div`
    position: fixed;
    z-index: 99;
    background-color: white;
    border-radius: 22px;
    width: 100%;
    height: 84.4%;
    bottom: 50px;
      overflow-y: scroll;

`
export const CloseButtonMobile = styled.div`
display: flex;
justify-content: end;
`
export const ModalCloseButtonMobile = styled.div`
border-bottom-left-radius: 12px;
    background-color: rgb(255, 87, 88);
    border: 0px;
    width: 10%;
    padding: 11px;
    border-top-right-radius: 23px;
    text-align: center;
`


//Sidebar responsive
export const SubMenuItem = styled.div`
display: flex;
flex-direction: column;
`