import React, { useState } from 'react'
import { ModalDarkBackGround, ModalStyle, ModalCloseButton, ModalCloseButtonLocationRes,CloseButton } from "../../styles/MainLayout"
import { ModalStyleMobile, ModalCloseButtonMobile, CloseButtonMobile, } from "../../styles/MainLayoutResponsive"
import AddLocations from '../../form/salons/addLocationForm';
import ModalPortal from '../../../portal/ModalPortal';

const AddLocation = (props) => {
    console.log('AddLocation 123', props.getData)
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    if (viewportSize > 800) {
        return (
            <>
                <ModalPortal>
                    <ModalDarkBackGround onClick={() => props?.handleModalClose()} />
                    <ModalStyle>
                    <CloseButton>
<ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
</CloseButton>
                        <AddLocations getData={props?.getData} handleClose={props?.handleModalClose} />
                    </ModalStyle>
                </ModalPortal>
            </>
        )
    }
    else {
        return (
            <>
                <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                <ModalStyleMobile>
                    <CloseButtonMobile>
                    <ModalCloseButtonLocationRes type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonLocationRes>
                    </CloseButtonMobile>
                    <AddLocations getData={() => props?.getData()} handleClose={() => props?.handleModalClose()} />
                </ModalStyleMobile>
            </>
        )
    }
}

export default AddLocation
