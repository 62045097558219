import React, { } from 'react'
import { useState } from 'react';
import {ModalDarkBackGround } from "../../../components/styles/MainLayout"
import ModalPortal from "../../../portal/ModalPortal";
import { FormTitle, DeleteModal, DeleteModalButton,ModalStyleDelete, ModalDeleteButton,ModalCancelButton, FormTitleResponsive } from "../../../components/styles/MainLayout";
import axios from '../../../axios';
import { toast } from 'react-toastify';

const LanguageDeleteModal = (props) => {
    //AXIOS (Delete)
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });

    const handleDelete = (e) => {
        e.preventDefault()
        axios
            .delete(`/api/admin/genders/${props?.dataToModal?.id}`)
            .then((response) => {
                console.log("olaLabels", response.data);
                toast.success("Gender deleted sucessfully.")
                props?.handleModalClose();
                props?.getNewSalon();
            }).catch(err => {
                toast.error("Something went wrong")
                console.log(err)
            })
    };
    //AXIOS
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal >
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyleDelete>
                            {/* <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton> */}
                            <DeleteModal className='mt-5'>
                                <i class="fa-solid fa-triangle-exclamation fa-fade display-1" style={{ color: "#c22929" }}></i>
                                <FormTitle className="d-flex justify-content-center mt-3">Are you sure you want to delete "{props?.dataToModal?.name_al}"? </FormTitle>
                                <p className='a'>Do you really want to delete this Gender? This <br />process cannot be undone.</p>
                            </DeleteModal>
                                <DeleteModalButton className=' mt-5'>
                                    <ModalCancelButton onClick={() => props.handleModalClose()}>Cancel</ModalCancelButton>
                                    <ModalDeleteButton onClick={(e) => handleDelete(e)} >Delete</ModalDeleteButton>
                                </DeleteModalButton>
                        </ModalStyleDelete>
                    </ModalPortal>
                </>
            )
        }
        else {
            return (
                <>
                     <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                    <ModalStyleDelete>
                            <DeleteModal className='mt-5'>
                                <i class="fa-solid fa-triangle-exclamation fa-fade display-1" style={{ color: "#c22929" }}></i>
                                <FormTitleResponsive className="d-flex justify-content-center mt-3">Are you sure you want to delete  "{props?.dataToModal?.name_al}"? </FormTitleResponsive>
                                <p className='a'>Do you really want to delete this gender? This process cannot be undone.</p>
                            </DeleteModal>
                                <DeleteModalButton className='mt-5'>
                                    <ModalCancelButton onClick={() => props.handleModalClose()}>Cancel</ModalCancelButton>
                                    <ModalDeleteButton onClick={(e) => handleDelete(e)} >Delete</ModalDeleteButton>
                                </DeleteModalButton>
                        </ModalStyleDelete>

                </>
            )
        }
    }
    else
        return null
}

export default LanguageDeleteModal
