import React, { useEffect, useRef, useState } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import axios from '../../../../axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import "../editSalon.scss"


const MapContainer = (props) => {

    const [markerPosition, setMarkerPosition] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [salons, setsalons] = useState([])
    const { id } = useParams();


    //WindowFlag
    const [selectedElement, setSelectedElement] = useState(null);
    const [activeMarker, setActiveMarker] = useState(null);
    const [showInfoWindow, setInfoWindowFlag] = useState(true);

    console.log("props?.addressId", props?.addressId)
    const handleMapClick = (mapProps, map, clickEvent) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();
        const newPosition = { lat, lng };
        setMarkerPosition(newPosition);
    };
    const getSalon = () => {
        axios.get(`/api/admin/salon_address/single/${id}`)
            .then(data => {
                console.log("SALON ADRES1111", data.data)
                setsalons(data.data)
            }).catch(err => {
                console.log(err)
            })
    }



    //AXIOS
    const editLocation = (e) => {
        e.preventDefault()
        let body = {
            salon_id: id,
            id: props?.addressId,
            street_address_al: props?.name_al,
            street_address_en: props?.name_al,
            latitude: markerPosition?.lat,
            longitude: markerPosition?.lng,
            desc_al: null,
            desc_en: null,
            created_by: 1
        }
        axios.put("/api/admin/salon_address/update", body)
            .then(data => {
                console.log("data salon address", data.data)
                toast.success("Salon address edited successfully")
            }).catch(err => {
                console.log(err)
            })
    }

    //AXIOS
    useEffect(() => {
        getSalon()
    }, [])

    return (
        <>
            <div className="col">
                <Map
                    className="mapEditSalon"
                    containerStyle={{ marginTop: '15px',height:'830px',position:'relative', border:'2px solid #b4dfc0',width:'500px' }}
                    google={props.google}
                    zoom={14}
                    initialCenter={{
                        lat: 42.66299247342869,
                        lng: 21.162134198227506
                    }}
                    //   ref={mapRef}
                    streetViewControl={false}
                    onClick={handleMapClick}
                >
                    {markerPosition && <Marker position={markerPosition} />}

                    <Marker
                        title={salons?.name_al}
                        name={'Marker Name'}
                        position={{ lat: salons?.latitude, lng: salons?.longitude }}
                        onClick={(props, marker) => {
                            setSelectedElement(salons);
                            setActiveMarker(marker);
                        }}
                    />


                    {selectedElement ? (
                        <InfoWindow
                            visible={showInfoWindow}
                            marker={activeMarker}
                            onCloseClick={() => {
                                setSelectedElement(null);
                            }}
                        >
                            <div>
                                <p className='infoWindow'>{selectedElement.name_al}</p>
                            </div>
                        </InfoWindow>
                    ) : null}

                    {/* <div style={{width:'300px' , height:'10vh'}}>
                <PlacesAutocomplete
                onChange={(address) => handlePlaceSelect(address)}
                />
            </div> */}
                </Map>
            </div>
            <div className="col">
                <form onSubmit={editLocation}>
                    <button disabled={props?.addressId == undefined} className='btn btn-md btn-success mt-2' type='submit'>Save</button>
                </form>
            </div>
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAx2mpUulK8e5rn9PB09xmSDDccbCoVMps'
})(MapContainer);
