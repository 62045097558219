import React, { } from 'react'
import { useState } from 'react';
import { ModalStyleMobile, ModalCloseButtonMobile, CloseButtonMobile} from "../../styles/MainLayoutResponsive"
import ModalPortal from "../../../portal/ModalPortal";
import {
    FormTitle, LanguagesForm, FormControl, ModalEditButton, ModalStyle, ModalCloseButton,
    ModalDarkBackGround, DivForLabelAndInputUser, LabelProfile, InputProfileForm, ModalEditButtonAdd, CloseButton
} from "../../styles/MainLayout";
import axios from '../../../axios';
import { toast } from 'react-toastify';
import "../../../components/form/LanguageForm/language.scss"
const LanguageModal = (props) => {
    console.log("a po vini", props)
    const [key, setKey] = useState("");
    const [label_al, setLabel_al] = useState("");
    const [label_en, setLabel_en] = useState("");
    const [oneTime, setOneTime] = useState(false);


    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    console.log('shabangashiprops', props)
    const body = {
        key: key,
        label_al: label_al,
        label_en: label_en
    };
    //AXIOS-- Add new Label
    const addPostLanguage = (e) => {
        e.preventDefault();
        setOneTime(true);

        axios
            .post("/api/admin/labels", body)
            .then((response) => {
                props?.handleModalClose();
                props?.getNewLabels()
                toast.success("New label created!")
                console.log(response)
                setOneTime(false);
            }).catch(err => {
                toast.error("Error creating new label!")

                console.log(err)
            })
    }
    //AXIOS-- Edit new Label
    const editBody = {
        id: props?.dataToModal?.id,
        key: key,
        label_al: label_al,
        label_en: label_en
    }
    const handleEdit = (e) => {
        e.preventDefault();
        axios
            .put(`/api/admin/labels/`, editBody)
            .then((response) => {
                console.log("olaLabels", response);
                props?.handleModalClose();
                props?.getNewLabels();
                toast.success("Label edited sucessfully.")
            }).catch(err => {
                toast.error(err)
                console.log(err)
            })
    };
    //AXIOS (Delete)
    const handleDelete = (e) => {
        e.preventDefault()
        axios
            .delete(`/api/admin/labels/${props?.dataToModal?.id}`)
            .then((response) => {
                console.log("olaLabels", response.data);
                toast.success("Label deleted sucessfully.")
                props?.handleModalClose();
                props?.getNewLabels();
            }).catch(err => {
                toast.error(err)
                console.log(err)
            })
    };
    //AXIOS

    console.log("PROPSAT", props)
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal>
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyle>
                            <CloseButton>
                                <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                            </CloseButton>
                            {props?.type === "add" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Add new label</FormTitle>
                                    <hr />
                                    <LanguagesForm onSubmit={(e) => addPostLanguage(e)} >
                                        <FormControl>
                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile>Key</LabelProfile>
                                                <InputProfileForm required onChange={(e) => setKey(e.target.value)} placeholder="Add a Key" type="text" />
                                            </DivForLabelAndInputUser>

                                            <LabelProfile>English</LabelProfile>
                                            <textarea className="form-control" cols="20" rows="2" placeholder="Add English description..." required onChange={(e) => setLabel_en(e.target.value)}></textarea>
                                            <br />
                                            <LabelProfile>Albania</LabelProfile>
                                            <textarea className="form-control" cols="20" rows="2" placeholder="Add Albania description..." required onChange={(e) => setLabel_al(e.target.value)}></textarea>
                                        <div className='d-flex justify-content-end mt-5 mb-5'>
                                        <ModalEditButtonAdd type="submit" disabled={oneTime === true ? true : false} className={oneTime !== true ? "oneTime" : ""} >Add</ModalEditButtonAdd>
                                        </div>
                                        </FormControl>
                                    </LanguagesForm>
                                </>
                                :
                                props?.type === "edit" ?
                                    <>
                                        <FormTitle className="d-flex justify-content-center mt-3">Edit </FormTitle>
                                        <hr />
                                        <LanguagesForm type={props?.type} onSubmit={handleEdit}>
                                            <FormControl>
                                                <DivForLabelAndInputUser className="forDisable">
                                                    <LabelProfile >Key</LabelProfile>
                                                    <InputProfileForm required placeholder="" type="text" defaultValue={props?.dataToModal?.key} onChange={(e) => setKey(e.target.value)} />
                                                </DivForLabelAndInputUser>
                                                <LabelProfile >English</LabelProfile>
                                                <textarea className="form-control" cols="30" rows="2" defaultValue={props?.dataToModal?.label_en} onChange={(e) => setLabel_en(e.target.value)}></textarea>
                                                <br />
                                                <LabelProfile >Albania</LabelProfile>
                                                <textarea className="form-control" cols="30" rows="2" required defaultValue={props?.dataToModal?.label_al} onChange={(e) => setLabel_al(e.target.value)}></textarea>
                                            <div className='d-flex justify-content-end mt-5 mb-5'>
                                            <ModalEditButtonAdd type="submit" >Edit</ModalEditButtonAdd>
                                            </div>
                                            </FormControl>
                                        </LanguagesForm>
                                    </>
                                    : ""
                            }
                        </ModalStyle>
                    </ModalPortal>
                </>
            )
        }
        //   ----MOBILE VIEW----- //
        else {
            return (
                <>
                    <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                    <ModalStyleMobile>
                        <CloseButtonMobile>
                        <ModalCloseButtonMobile type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButtonMobile>
                        </CloseButtonMobile>
                        {props?.type === "add" ?
                            <>
                                <FormTitle className="d-flex justify-content-center mt-3">Add new label</FormTitle>
                                <hr />
                                <LanguagesForm onSubmit={(e) => addPostLanguage(e)} >
                                    <FormControl>
                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile>Key</LabelProfile>
                                            <InputProfileForm required onChange={(e) => setKey(e.target.value)} placeholder="Add a Key" type="text" />
                                        </DivForLabelAndInputUser>

                                        <LabelProfile>English</LabelProfile>
                                        <textarea className="form-control" cols="30" rows="2" defaultValue={props?.dataToModal?.label_en} onChange={(e) => setLabel_en(e.target.value)}></textarea>

                                        <br />
                                        <LabelProfile>Albania</LabelProfile>
                                        <textarea className="form-control" cols="30" rows="2" required defaultValue={props?.dataToModal?.label_al} onChange={(e) => setLabel_al(e.target.value)}></textarea>

                                    <div className='d-flex justify-content-end mt-4 mb-2'>
                                    <ModalEditButton type="submit" >Add</ModalEditButton>
                                    </div>
                                    </FormControl>

                                </LanguagesForm>
                            </>
                            :
                            props?.type === "edit" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Edit </FormTitle>
                                    <hr />
                                    <LanguagesForm type={props?.type} onSubmit={handleEdit}>
                                        <FormControl>
                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile >Key</LabelProfile>
                                                <InputProfileForm required placeholder="" type="text" defaultValue={props?.dataToModal?.key} onChange={(e) => setKey(e.target.value)} />
                                            </DivForLabelAndInputUser>
                                            <br />
                                            <LabelProfile >English</LabelProfile>
                                            <textarea className="form-control" cols="30" rows="2" defaultValue={props?.dataToModal?.label_en} onChange={(e) => setLabel_en(e.target.value)}></textarea>
                                            <br />
                                            <LabelProfile >Albania</LabelProfile>
                                            <textarea className="form-control" cols="30" rows="3" required defaultValue={props?.dataToModal?.label_al} onChange={(e) => setLabel_al(e.target.value)}></textarea>
                                        <div className='d-flex justify-content-end mt-4 mb-2'>
                                        <ModalEditButton type="submit" >Save</ModalEditButton>
                                        </div>
                                        </FormControl>
                                    </LanguagesForm>
                                </>
                                : ""
                        }
                    </ModalStyleMobile>
                </>
            )
        }
    }
    else
        return null
}
export default LanguageModal

